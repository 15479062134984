@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      no-repeat center right 1.75rem/1.125rem 1.125rem;
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block;
}
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      no-repeat center right 1.75rem/1.125rem 1.125rem;
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block;
}
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dropdown-item:last-child {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(2.875rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.8125rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion .card {
  overflow: hidden;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion .card .card-header {
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-3by4::before {
  padding-top: 133.333333%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */


/* @import "Custome.css"; */

/**  =====================
      Custom css start
==========================  **/
body{
  font-family: Arial, Helvetica, sans-serif
}
.img-radius {
  border-radius: 50%;
}

.page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;
}
.page-header-title + .breadcrumb > .breadcrumb-item a {
  color: #888;
}
.page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
  color: #111;
  font-weight: 600;
}

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.theme-bg2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

/* ==========  card css start  =========== */
.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
          animation: anim-rotate 1s linear infinite;
}

@-webkit-keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
          animation: anim-close-card 1.4s linear;
}

@-webkit-keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
p.text-muted {
  font-size: 13px;
}

.col-md-9-card .card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}
.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}
.card .card-header h5 {
  margin-bottom: 0;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}
.card .card-header h5:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: -25px;
  top: 0;
  width: 4px;
  height: 20px;
}
.card .card-header.borderless {
  border-bottom: none;
}
.card .card-header.borderless h5:after {
  display: none;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  /* float: left; */
  padding: 0;
  position: absolute;
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.card .card-footer {
  border-top: 1px solid #f1f1f1;
  background: transparent;
  padding: 25px;
}
.card .card-block,
.card .card-body {
  padding: 0;
}
.card.card-load {
  position: relative;
  overflow: hidden;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: #04a9f5;
  font-size: 24px;
  align-items: center;
  display: flex;
}
.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

/* ==========  scrollbar End  =========== */
.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */
.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;
}
.dropdown-menu.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: 15px;
  top: -5px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu.show.dropdown-menu-right:before {
  left: auto;
  right: 5px;
}
.dropdown-menu[x-placement="top-start"] {
  margin-bottom: 15px;
  margin-top: 0;
}
.dropdown-menu[x-placement="top-start"].show:before {
  content: "\64";
  bottom: -5px;
  top: auto;
  text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu > li {
  padding-left: 15px;
  padding-right: 15px;
}
.dropdown-menu > li > a {
  padding: 5px;
  color: #888;
}
.dropdown-menu > li > a i {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .marketingHome .container {
    max-width: 1400px;
  }
}

/* ========================================================
 ===============     document      ======================
 ========================================================


/* Example modals */
.modal {
  z-index: 1072;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}
.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}


.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */

/* Modal */
.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  transform-origin: 50% 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;
}
.datta-example-modal-opened .datta-example-modal {
  transform: scale(1);
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;
}
.datta-example-modal-content > pre {
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.datta-example-modal-content > pre > code {
  padding: 0;
  background: none;
  font-size: 16px;
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #04a9f5;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
}
.md-datta-example-modal-copy:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}
.md-datta-example-modal-copy.copied::before {
  content: "Copied to Clipboard Successfully ! . . .";
  position: absolute;
  display: block;
  right: 100%;
  margin-right: 10px;
  font-size: 14px;
  background: #1de9b6;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 6px;
  top: 50%;
  margin-top: -12px;
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.datta-example-modal-close:hover {
  color: #fff;
  opacity: 0.9;
}

/* ================================    range slider Start  ===================== */
.tooltip.in {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px;
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */
.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: #f0f3f6;
}
.bootstrap-tagsinput .tag {
  padding: 5px 12px;
  border-radius: 2px;
  line-height: 37px;
  margin-top: 5px;
  margin-right: 5px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-right: -5px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\e8f6";
  padding: 0 2px;
  font-family: 'feather' !important;
}

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */
.ms-container {
  width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #eaeaea;
  padding: 0.5rem 1rem;
}
.blockquote.text-right {
  border-left: none;
  border-right: 0.25rem solid #eaeaea;
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning h1 {
  color: #fff;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

/* ================================    browser  warning  End  ===================== */
/* material icon for material datetime picker */
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
}

/* material icon for material datetime picker */
/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-c-blue {
  background: #04a9f5;
}

.text-c-blue {
  color: #04a9f5;
}

.bg-c-red {
  background: #f44236;
}

.text-c-red {
  color: #f44236;
}

.bg-c-green {
  background: #1de9b6;
}

.text-c-green {
  color: #1de9b6;
}

.bg-c-yellow {
  background: #f4c22b;
}

.text-c-yellow {
  color: #f4c22b;
}

.bg-c-purple {
  background: #a389d4;
}

.text-c-purple {
  color: #a389d4;
}

.navbar-collapsed .b-brand .b-title {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed:hover .b-brand .b-title {
  transform: rotateY(0deg);
  opacity: 1;
}
.navbar-collapsed:hover .mobile-menu {
  transition-delay: 0.3s;
  transform: rotateY(0deg);
  opacity: 1;
}

/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
  content: '';
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}
.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

/* Pre-loader css end */
/* header css start */
.pcoded-header {
  z-index: 1028;
  position: relative;
  display: flex;
  min-height: 70px;
  padding: 0;
  position: relative;
  top: 0;
  background: transparent;
  color: #3f4d67;
  width: calc(100% - 264px);
  margin-left: 264px;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .m-header {
  display: none;
}
.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: #3f4d67;
}
.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #04a9f5;
}
.pcoded-header #mobile-header {
  display: none;
}
.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}
.pcoded-header .navbar-nav > li {
  line-height: 70px;
  display: inline-block;
  padding: 0 12px;
}
.pcoded-header .navbar-nav > li .nav-link {
  padding: 0;
}
.pcoded-header .navbar-nav > li:first-child {
  padding-left: 25px;
}
.pcoded-header .navbar-nav > li:last-child {
  padding-right: 40px;
}
.pcoded-header .mr-auto .dropdown-menu {
  margin-left: -20px;
}
.pcoded-header .ml-auto {
  float: right;
}
.pcoded-header .ml-auto .dropdown-menu {
  margin-right: -20px;
}
.pcoded-header .main-search .input-group {
  border-radius: 20px;
  padding: 0;
}
.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.pcoded-header .main-search .input-group .search-close {
  display: none;
}
.pcoded-header .main-search .input-group .search-btn {
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.pcoded-header .main-search .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
  font-size: 14px;
}
.pcoded-header .main-search .input-group .form-control:active, .pcoded-header .main-search .input-group .form-control:focus, .pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .main-search.open .input-group {
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
  padding: 5px 5px 5px 20px;
}
.pcoded-header .main-search.open .input-group .search-close {
  display: flex;
}
.pcoded-header .main-search.open .input-group .search-btn {
  padding: 5px;
  margin-left: 5px;
  background: #04a9f5;
  border-color: #04a9f5;
}
.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header .main-search.open .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
}
.pcoded-header .main-search.open .input-group .form-control:active, .pcoded-header .main-search.open .input-group .form-control:focus, .pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}
.pcoded-header .dropdown .dropdown-toggle {
  line-height: 70px;
  display: inline-block;
  padding-right: 15px;
}
.pcoded-header .dropdown .dropdown-toggle:after {
  content: "\e842";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}
.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  border: none;
  margin-top: -6px;
  min-width: 290px;
}
.pcoded-header .dropdown .dropdown-menu.show:before {
  display: none;
}
.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}
.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}

.pcoded-header .dropdown.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: -5px;
  top: 60px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12);
}
.pcoded-header .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .notification .noti-head {
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;
}
.pcoded-header .dropdown .notification .noti-head a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
}
.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}
.pcoded-header .dropdown .notification .noti-body li {
  padding: 15px 20px;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}
.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}
.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}
.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}
.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
}
.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}
.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #04a9f5;
}
.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #888;
  font-size: 14px;
  padding: 10px 20px;
}
.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-body li.active, .pcoded-header .dropdown .profile-notification .pro-body li:active, .pcoded-header .dropdown .profile-notification .pro-body li:focus, .pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .profile-notification .pro-body li.active > a, .pcoded-header .dropdown .profile-notification .pro-body li:active > a, .pcoded-header .dropdown .profile-notification .pro-body li:focus > a, .pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
  background: transparent;
}
.pcoded-header .dropdown.drp-user.show:before {
  color: #04a9f5;
}

/* header css end */
/* menu[ vartical ] css start */
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
}
.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after, .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #fff;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.mobile-menu.on span {
  background-color: transparent;
}
.mobile-menu.on span:after, .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.mobile-menu.on span:before {
  transform: rotate(45deg) translate(4px, 4px);
}
.mobile-menu.on span:after {
  transform: rotate(-45deg) translate(3px, -3px);
}

.navbar-brand {
  background: #3f4d67;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1029;
  position: fixed;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 0 20px 0 #3f4d67;
  width: 264px;
  height: 100vh;
  top: 0;
  background: #3f4d67;
  color: #a9b7d0;
}
.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.pcoded-navbar .scroll-div.navbar-content {
  height: calc(100vh - 70px);
}
.pcoded-navbar .header-logo {
  position: relative;
  align-items: center;
  display: inline-flex;
  float: left;
  height: 70px;
  text-align: center;
  width: 264px;
  margin-right: 0;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar .header-logo .logo-thumb {
  transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  transition: unset;
}
.pcoded-navbar .header-logo + .scroll-div {
  /* float: left; */
  display: inline-block;
}
.pcoded-navbar .mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #a9b7d0;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after, .pcoded-navbar .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #a9b7d0;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.pcoded-navbar .mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.pcoded-navbar .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar .mobile-menu.on span:after, .pcoded-navbar .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.pcoded-navbar .mobile-menu.on span:before {
  transform: rotate(45deg) translate(4px, 4px);
}
.pcoded-navbar .mobile-menu.on span:after {
  transform: rotate(-45deg) translate(3px, -3px);
}
.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: absolute;
  right: 30px;
  top: 12px;
  padding: 2px 7px;
  border-radius: 2px;
}
.pcoded-navbar .pcoded-inner-navbar {
  flex-direction: column;
}
.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 7px 15px;
  margin: 5px 0 0;
  display: block;
  border-radius: 0;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
  font-size: 1rem;
  padding: 4px 7px;
  margin-right: 7px;
  border-radius: 4px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  text-align: center;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
  position: absolute;
  top: 11px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e844";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 11px;
  right: 20px;
  transition: 0.3s ease-in-out;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotate(90deg);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > a {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 7px 7px 7px 60px;
  margin: 0;
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 35px;
  width: 5px;
  height: 5px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 10px;
  font-weight: 600;
  padding: 25px 20px 5px;
  text-transform: uppercase;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.disabled > a {
  cursor: default;
  opacity: 0.5;
}
.pcoded-navbar .pcoded-submenu {
  background: #39465e;
  padding: 15px 0;
}
.pcoded-navbar a {
  color: #a9b7d0;
}
.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}
.pcoded-navbar.navbar-collapsed {
  width: 80px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .header-logo {
  width: 80px;
}
.pcoded-navbar.navbar-collapsed .header-logo img {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: unset;
}
.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  transform: rotateY(0deg);
  transform-origin: 0 0;
  opacity: 1;
  left: calc((80px / 2) - 20px);
}
.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  z-index: 1026;
  padding: 7px 25px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  margin-left: 264px;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}


body.layout-1 .pcoded-navbar .toggle-sidemenu {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #2c3547;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 0 0;
}
body.layout-1 .pcoded-navbar.navbar-collapsed .toggle-sidemenu {
  display: none;
}
body.layout-1 .pcoded-navbar .header-logo {
  padding: 10px 20px;
}
body.layout-1 .pcoded-navbar .sidemenu {
  left: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: inset -1px 0 0 0 rgba(169, 183, 208, 0.2);
}
body.layout-1 .pcoded-navbar .sidemenu li {
  position: relative;
}
body.layout-1 .pcoded-navbar .sidemenu li a {
  font-size: 22px;
  padding: 10px 29px;
  position: relative;
  cursor: pointer;
}
body.layout-1 .pcoded-navbar .sidemenu li a:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 60%;
  left: 20%;
  bottom: 0;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}
body.layout-1 .pcoded-navbar .sidemenu li:last-child a:after {
  display: none;
}
body.layout-1 .pcoded-navbar .sidemenu li.active {
  color: #04a9f5;
}
body.layout-1 .pcoded-navbar .sidemenu li.active:after {
  content: "";
  background-color: #04a9f5;
  z-index: 1027;
  position: absolute;
  right: 0;
  top: 7px;
  width: 3px;
  height: calc(100% - 14px);
  border-radius: 3px 0 0 3px;
}
body.layout-1 .pcoded-navbar .layout1-nav {
  display: flex;
  align-items: stretch;
  height: auto;
  min-height: 100%;
}
body.layout-1 .pcoded-navbar .side-content {
  flex: 1 1;
}
body.layout-1 .pcoded-navbar .side-content .sidelink {
  display: none;
}
body.layout-1 .pcoded-navbar .side-content .sidelink.active {
  display: block;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 16px;
  padding: 16px 15px 16px 18px;
  font-weight: 400;
  text-transform: capitalize;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 40px;
  left: 18px;
  bottom: 13px;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  padding: 7px 7px 7px 40px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  left: 20px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a {
  padding: 7px 7px 7px 60px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a:before {
  left: 40px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a {
  padding: 12px 10px 12px 20px;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-micon {
  display: none;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-mtext {
  position: relative;
  top: 0;
}
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.active:after, body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.pcoded-trigger:after {
  display: none;
}
body.layout-1 .pcoded-navbar.hide-sidemenu .sidemenu {
  position: absolute;
  left: -80px;
}
body.layout-1 .pcoded-navbar.hide-sidemenu .toggle-sidemenu i {
  transform: rotate(180deg);
}

.navbar-content {
  position: relative;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent;
}

.ps__thumb-y {
  background: #2a3446;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #222a38;
}

.gradientcolor .btn-round {
  border-radius: 30px;
}

.ChartShadow {
  -webkit-filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
}

.chart-activity,
.chart-sale,
.chart-statistics,
.device-chart {
  margin: 0 auto;
}

#Statistics-sale .amcharts-cursor-fill {
  -webkit-filter: url(#shadow);
          filter: url(#shadow);
  fill: rgba(255, 255, 255, 0.3);
  stroke-width: 0;
}

.amcharts-zoom-out-bg,
.amcharts-zoom-out-image {
  display: none;
}

/* statistics section */
.card-command .card-icon {
  opacity: 0.5;
}

.mat-drp .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #d8d8d8;
}
.mat-drp .btn.dropdown-toggle:after {
  display: none;
}
.mat-drp .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.table-card .row-table i {
  padding: 50px 20px;
}
.table-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.table-card .row-table > [class*=col-] .row {
  display: flex;
  align-items: center;
}

.rides-bar i.rides-icon {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 15px;
}

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.profit-bar i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 13px;
}

.assets-value .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center 103%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.assets-value .card-block {
  position: relative;
  z-index: 5;
}



@media screen and (max-width: 1199px) {
  .assets-value .bg-img {
    background: none;
  }
}

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%);
}
.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative;
}
.chat-sanders .scroll-wrapper {
  height: 305px;
}
.chat-sanders .received-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -12px;
  bottom: 18px;
  transform: rotate(270deg);
  border: 6px solid transparent;
  border-bottom-color: #fff;
}
.chat-sanders .send-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .send-chat .msg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .send-chat .msg:after {
  content: "";
  position: absolute;
  right: -11px;
  bottom: 18px;
  transform: rotate(450deg);
  border: 6px solid transparent;
  border-bottom-color: #1de9b6;
}
.chat-sanders .btn {
  background: none;
  opacity: 0.4;
}
.chat-sanders .form-control {
  background: #f4f7fa;
}
.chat-sanders .input-group {
  background: transparent;
}

.widget-menu {
  background: linear-gradient(-135deg, #88d3ce 0%, #6e45e2 100%);
}
.widget-menu .widget-title {
  border-top: 1px solid #676fb9;
}
.widget-menu i {
  opacity: 0.5;
}

.to-do .to-do-button {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.to-do .to-do-button .btn {
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 50px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}

.lazy-dog i {
  opacity: 0.5;
}

.widget-content .widget-lorem .media h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #e3e3e3;
}
.note-bar .friendlist-box:first-child {
  border: 0;
}
.note-bar .friendlist-box h6 {
  display: inline-block;
}
.note-bar .friendlist-box i {
  opacity: 0.2;
  color: #888;
}

.to-do-list h6 {
  display: inline-block;
}
.to-do-list .done-task {
  opacity: 0.7;
}
.to-do-list .done-task > div {
  -webkit-filter: grayscale(0.8);
          filter: grayscale(0.8);
}
.to-do-list .done-task h6,
.to-do-list .done-task p,
.to-do-list .done-task span {
  text-decoration: line-through;
}
.to-do-list .checkbox-fade .check-task {
  display: block;
}
.to-do-list .checkbox-fade .to-content {
  display: inline-block;
}
.to-do-list .checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  /* float: right; */
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin: 0;
  top: 5px;
}
.to-do-list .checkbox-fade .cr .cr-icon {
  color: #fff;
  font-size: 0.8em;
  left: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}
.to-do-list .checkbox-fade label input[type=checkbox] {
  display: none;
}
.to-do-list .checkbox-fade label input[type=checkbox] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border: 0;
}

.User-Activity .table td,
.user-list .table td {
  vertical-align: middle;
}
.User-Activity .table thead th,
.user-list .table thead th {
  border: 0;
}

.Application-list .table td,
.code-table .table td {
  vertical-align: middle;
}
.Application-list .table .label,
.code-table .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.Application-list thead th,
.code-table thead th {
  border: 0;
}

.Recent-Users .table tr:first-child td,
.User-Lists .table tr:first-child td {
  border-top: 0;
}
.Recent-Users .table td,
.User-Lists .table td {
  vertical-align: middle;
}
.Recent-Users .table .label,
.User-Lists .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center;
}
.social-media .progress h5 {
  position: relative;
  top: -2px;
}

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none;
}
.post-emoticon li {
  display: inline-block;
}
.post-emoticon i {
  position: relative;
  top: 4px;
}

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.loction-user .row {
  padding: 35px 30px;
}
.loction-user i {
  opacity: 0.5;
}
.loction-user .loction-progress {
  padding: 35px 30px;
}
.loction-user .loction-progress .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
}
.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}
.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3;
}

.ux-designer {
  position: relative;
  padding: 35px 30px;
}
.ux-designer .btn {
  border-radius: 50px;
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: -20px;
  padding: 0;
  margin: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0;
}
.task-list:after {
  content: "";
  position: absolute;
  background: #ecedef;
  height: 100%;
  width: 2px;
  top: 0;
  left: 30px;
  z-index: 1;
}
.task-list li {
  margin-bottom: 30px;
  padding-left: 55px;
  position: relative;
}
.task-list li:last-child {
  margin-bottom: 0;
}
.task-list li .task-icon {
  position: absolute;
  left: 22px;
  top: 13px;
  border-radius: 50%;
  padding: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-main {
  padding: 30px 25px;
}
.project-main i {
  opacity: 0.4;
}

.user-chart i {
  opacity: 0.3;
}

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0;
}

.user-card .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.Active-visitor .progress {
  height: 7px;
}
.Active-visitor .card-active > div + div {
  border-left: 1px solid #eaeaea;
}
@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px;
  }
}

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px;
}
.Invoice-bar i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .location-sale .card-icon {
    display: none;
  }
}

.card-Impression i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.card-Revenue i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.card-customer i {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 375px) {
  .card-customer i {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}

.ticket-customer i {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px;
}

.ticket-visitor .progress {
  border-radius: 0;
  height: 13px;
}

.customer-visitor i {
  opacity: 0.2;
  font-size: 118px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-social:hover i {
  font-size: 40px;
  transition: all 0.3s ease-in-out;
}
.card-social .progress {
  height: 6px;
}
.card-social .card-active > div + div {
  border-left: 1px solid #eaeaea;
}

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px;
}

.team-leader .slide {
  margin-bottom: 32px;
}
.team-leader .slide li {
  width: 10px;
  height: 10px;
  background: #e3e3e3;
  margin: 0 6px;
  border-radius: 50px;
}
.team-leader .slide .carousel-indicators {
  bottom: -44px;
}
.team-leader .slide .carousel-indicators .active {
  background: #869791;
}

.affilate-offers .card-icon {
  opacity: 0.5;
}
.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px;
}

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0;
}
.earning-date .bd-example .nav-pills {
  background: transparent;
  padding: 0;
  box-shadow: none;
}
.earning-date .bd-example .nav-pills .nav-link {
  min-width: auto;
  padding: 6px 20px;
  color: #fff;
}
.earning-date .bd-example .nav-pills .nav-link.active {
  background: #fff;
  box-shadow: none;
  color: #000;
}
.earning-date .bd-example .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 11px;
}
.earning-date .bd-example .tab-content {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.sale-view i {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-task i {
  opacity: 0.5;
}
.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px;
}
.project-task .progress {
  height: 6px;
}

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px;
}
.card-event i {
  position: absolute;
  bottom: 36px;
  right: 27px;
}

.bitcoin-wallet i {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}

.summary-box .card-icon {
  opacity: 0.4;
}

.feature-card-box .feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  padding: 10px;
  font-size: 35px;
  transition: all 0.3s ease-in-out;
}
.feature-card-box:hover .feature-icon {
  transform: scale(1.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
  border-radius: 3px;
  padding: 0 3px;
}

@media screen and (max-width: 767px) {
  .user-designer [class*=col-]:last-child {
    margin-top: 15px;
  }

  div.dataTables_wrapper div.dataTables_filter label {
    float: none !important;
    text-align: center;
  }
}
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

/* ===================== to do page ============================= */
#task-container ul {
  overflow: hidden;
  padding: 0;
}
#task-container li {
  /* float: left; */
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  color: #666;
  border-top: 5px solid #04a9f5;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  transition: all 0.3s;
  position: relative;
}
#task-container li:nth-child(2n) {
  margin-right: 0;
}
#task-container li.complete {
  opacity: 1;
  border-top: 9px solid #f44236;
  transition: all ease-in 0.3s;
}

#task-container li.complete p {
  text-decoration: line-through;
}
@media screen and (max-width: 580px) {
  #task-container li {
    width: 100%;
  }
  
  .auth-wrapper .auth-content {
    width: 100%;
  }
  .programcode-card-new {
    width: 90%;
    height: auto;
    margin: auto;
}
.auth-content .card .card-block, .auth-content .card .card-body {
  padding: 25px !important;
}
.auth-wrapper .card {
  margin-bottom: 0;
  width: 90%;
  margin: auto;
}
}

.new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px;
}
.new-task label input[type=checkbox] {
  display: none;
}

.checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  /* float: left; */
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin-right: 16px;
  top: 0;
}

.task-panel .to-do-label {
  margin-bottom: 15px;
}
.task-panel .to-do-label:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding: 0;
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0;
}
.to-do-list:hover i {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.note-card .note-box-aside {
  border-right: 1px solid #ddd;
}
.note-card .Note-header {
  padding: 20px 0;
}
.note-card #Note-pad {
  border: none;
  resize: none;
  background: 0 0;
  padding: 0 20px 0 50px;
  line-height: 35px;
}
.note-card .note-write {
  position: relative;
  background: -webkit-linear-gradient(top, #98dcfa 0%, #e1f5fe 5%) 0 0;
  background-size: 100% 35px;
}
.note-card .note-write:before {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 32px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .note-write:after {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 34px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  transform: translateX(10px);
}
.note-card #Note-list li:hover .Note-delete {
  opacity: 1;
  transform: translateX(0px);
}

/* ===================== Gallery page ============================= */
.gallery-masonry .card-columns {
  -webkit-column-count: 4;
          column-count: 4;
}

@media screen and (max-width: 1400px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (max-width: 992px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (max-width: 575px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}
.job-meta-data i {
  margin-right: 5px;
  color: #04a9f5;
}

/* ===================== Task-list page ============================= */
.task-data img {
  width: 40px;
  box-shadow: none;
}
.task-data i {
  color: #111;
}
.task-data .dropdown-toggle:after {
  color: #111;
}

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
.task-board-left .taskboard-right-progress,
.task-board-left .taskboard-right-revision,
.task-board-left .taskboard-right-users {
  padding-top: 10px;
}
.task-board-left .taskboard-right-progress .progress {
  height: 9px;
  margin-bottom: 25px;
}
.task-board-left .user-box .media-object {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.task-board-left .user-box .media-left {
  position: relative;
}
.task-board-left .user-box .btn.btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-board-left .live-status {
  height: 8px;
  width: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  top: 5px;
}

.filter-bar .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  padding: 0.5rem 1rem;
}
@media screen and (max-width: 557px) {
  .filter-bar .navbar .f-text {
    display: block;
    width: 100%;
  }
  .filter-bar .navbar .f-view {
    padding-left: 24px;
  }
  .filter-bar .navbar .f-view span {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
.filter-bar .label {
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 7px;
}
.filter-bar .task-detail {
  margin-bottom: 5px;
}
.filter-bar .card-task .task-list-table {
  display: inline-block;
}
.filter-bar .card-task .task-list-table img {
  width: 40px;
  box-shadow: none;
}
.filter-bar .card-task .task-list-table i {
  color: #111;
}
.filter-bar .card-task .task-board {
  float: right;
  margin-top: 5px;
}
.filter-bar .card-task .task-board .dropdown {
  display: inline-block;
}
.filter-bar .card-task .task-board .btn {
  padding: 4px 10px;
  font-size: 10px;
  margin: 0;
}

.assign-user img,
.task-comment img {
  width: 45px;
  box-shadow: none;
}

/* ===================== Invoice page ============================= */
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
}

.invoice-table.table {
  padding-left: 20px;
}
.invoice-table.table td {
  border: 0;
  padding: 4px 0;
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;
}
.invoive-info h6 {
  margin-bottom: 20px;
  text-transform: uppercase;
}
.invoive-info .invoice-order.table {
  padding-left: 0;
}
.invoive-info .invoice-order.table th {
  border: 0;
  padding: 4px 0;
}
.invoive-info .invoice-order.table th:first-child {
  padding-left: 0;
  width: 80px;
}

.invoice-total.table {
  background: #f3f3f3;
  padding: 30px 0;
}
.invoice-total.table th {
  border: 0;
  padding: 4px 0;
  text-align: right;
}
.invoice-total.table td {
  text-align: right;
}
.invoice-total.table tbody {
  padding-right: 20px;
  float: right;
}

.invoice-summary .label {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px;
}

.invoice-list .btn {
  padding: 5px 10px;
  font-size: 12px;
}
.invoice-list .task-list-table {
  display: inline-block;
}

/*  ================ new css =================== */
.datepicker-dropdown {
  padding: 20px;
  color: #fff;
  background: #3f4d67;
  font-size: 14px;
}
.datepicker-dropdown:after {
  border-bottom: 6px solid #3f4d67;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #3f4d67;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active, .datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active, .datepicker table tr td.highlighted,
.datepicker table tr td.today, .datepicker table tr td.day:hover, .datepicker table tr td.focused, .datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background-color: #333f54;
  color: #fff;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover,
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #ffffff94;
}

.syntax-output {
  border-radius: 0.25rem;
  border: 1px solid #eaeaea;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px !important;
  background-color: #04a9f5;
  color: #fff;
}
.owl-carousel button:focus {
  outline: none;
  color: #fff;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #04a9f5;
  color: #fff;
}

.counter b {
  font-size: 24px;
}

.tour-mobile .error-block {
  display: none;
}

.message-mobile span {
  font-size: 16px;
}
.message-mobile .task-right-header-status {
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .message-mobile .task-right-header-status {
    display: none;
  }
}

.fullcalendar-card .fc-button {
  background-color: #04a9f5;
  border-color: #fff;
  color: #fff;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  height: 37px;
  padding: 0 15px;
}
.fullcalendar-card h2 {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .fullcalendar-card .fc .fc-toolbar > * > * {
    float: none;
  }
  .fullcalendar-card .fc-toolbar .fc-left, .fullcalendar-card .fc-toolbar .fc-right, .fullcalendar-card .fc-toolbar .fc-center {
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ck-content strong {
  font-weight: 600;
}

div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  box-shadow: none !important;
}

pre[class*=language-]:after, pre[class*=language-]:before {
  display: none;
}

#chat-scroll {
  height: 280px;
  position: relative;
}

.msg-user-list.scroll-div {
  height: calc(100vh - 300px);
  position: relative;
}

.msg-user-chat.scroll-div {
  height: calc(100vh - 330px);
  position: relative;
}

@media screen and (max-width: 1199px) {
  .note-card .note-box-aside {
    border: none !important;
  }
}
@media screen and (max-width: 991px) {
  .ch-block {
    display: none;
  }

  .msg-block.dis-chat .taskboard-right-progress {
    display: none;
  }
  .msg-block.dis-chat .ch-block {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .tour-mobile .error-block {
    display: block;
  }

  .tour-mobile .page-wrapper {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .horizontal-mobile {
    display: none;
  }
}
/**  =====================
      Form Componant css start
==========================  **/
.custom-select,
.form-control {
  background: #f4f7fa;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
}
/* .custom-select:focus,
.form-control:focus {
  background: #f4f7fa;
} */
.custom-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #aaaeb3;
  opacity: 1;
}
.custom-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #aaaeb3;
}
.custom-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #aaaeb3;
}

/* input group start */
.input-group {
  background: #f4f7fa;
}
.input-group .input-group-text {
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
}
.input-group .input-group-text i {
  font-size: 20px;
}
.input-group .custom-file-label {
  margin-bottom: 0;
}
.input-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5;
}
.cust-file-button .custom-file-label::after {
  padding: 0.775rem 0.75rem;
  height: 2.65rem;
}

.custom-select {
  height: calc(2.25rem + 9px);
}

/* input group End */
/**====== Form Componant css end ======**/
select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors .form-control {
  padding: 6px 12px 5px 44px;
}

/* form-select */
.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.checkbox input[type=checkbox] {
  margin: 0;
  display: none;
  width: 22px;
}
.checkbox input[type=checkbox] + .cr {
  padding-left: 0;
}
.checkbox input[type=checkbox] + .cr:before {
  content: "\e83f";
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'feather';
  font-weight: 400;
  line-height: 19px;
  vertical-align: bottom;
  text-align: center;
  background: #ffffff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.checkbox input[type=checkbox]:checked + .cr:before {
  background: #1dd5d2;
  border-color: #1dd5d2;
  color: #ffffff;
}
.checkbox input[type=checkbox].disabled + .cr, .checkbox input[type=checkbox]:disabled + .cr {
  opacity: 0.5;
}
.checkbox input[type=checkbox].disabled + .cr:before, .checkbox input[type=checkbox]:disabled + .cr:before {
  cursor: not-allowed;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:after {
  content: "";
  width: 22.5px;
  height: 22.5px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 2px;
  vertical-align: bottom;
  text-align: center;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 8.5px;
  left: 3px;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:before {
  opacity: 0;
  content: "\e840";
  font-size: 27px;
  background: transparent;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:after {
  opacity: 0;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:before {
  opacity: 1;
  background: transparent;
  color: #1dd5d2;
  border-color: transparent;
}
.checkbox.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: #04a9f5;
  border-color: #04a9f5;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #04a9f5;
  border-color: transparent;
}
.checkbox.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: #f44236;
  border-color: #f44236;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f44236;
  border-color: transparent;
}
.checkbox.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: #1de9b6;
  border-color: #1de9b6;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #1de9b6;
  border-color: transparent;
}
.checkbox.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: #f4c22b;
  border-color: #f4c22b;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f4c22b;
  border-color: transparent;
}
.checkbox.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: #3ebfea;
  border-color: #3ebfea;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #3ebfea;
  border-color: transparent;
}
.checkbox .cr {
  cursor: pointer;
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.radio input[type=radio] {
  margin: 0;
  display: none;
  width: 22px;
}
.radio input[type=radio] + .cr {
  padding-left: 0;
}
.radio input[type=radio] + .cr:after, .radio input[type=radio] + .cr:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: bottom;
  background: #fff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.radio input[type=radio] + .cr:before {
  width: 22px;
  height: 22px;
  border: 2px solid #e9eaec;
}
.radio input[type=radio] + .cr:after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 13px;
  left: 5px;
}
.radio input[type=radio]:checked + .cr:before {
  border-color: #1dd5d2;
}
.radio input[type=radio]:checked + .cr:after {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.radio input[type=radio]:disabled + .cr {
  opacity: 0.5;
  cursor: not-allowed;
}
.radio input[type=radio]:disabled + .cr:after, .radio input[type=radio]:disabled + .cr:before {
  cursor: not-allowed;
}
.radio.radio-fill input[type=radio] + .cr:after {
  width: 18px;
  height: 18px;
  top: 10px;
  left: 2px;
}
.radio.radio-primary input[type=radio]:checked + .cr:before {
  border-color: #04a9f5;
}
.radio.radio-primary input[type=radio]:checked + .cr:after {
  background: #04a9f5;
}
.radio.radio-danger input[type=radio]:checked + .cr:before {
  border-color: #f44236;
}
.radio.radio-danger input[type=radio]:checked + .cr:after {
  background: #f44236;
}
.radio.radio-success input[type=radio]:checked + .cr:before {
  border-color: #1de9b6;
}
.radio.radio-success input[type=radio]:checked + .cr:after {
  background: #1de9b6;
}
.radio.radio-warning input[type=radio]:checked + .cr:before {
  border-color: #f4c22b;
}
.radio.radio-warning input[type=radio]:checked + .cr:after {
  background: #f4c22b;
}
.radio.radio-info input[type=radio]:checked + .cr:before {
  border-color: #3ebfea;
}
.radio.radio-info input[type=radio]:checked + .cr:after {
  background: #3ebfea;
}
.radio .cr {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .radio input[type="radio"] + .cr::after {
    top: 14px;
  }
}
.custom-controls-stacked .radio input[type=radio] + .cr:after {
  top: 15px;
}

/**====== Radio & Checked css end ======**/
/**  =====================
      Label & Badges css start
==========================  **/
.label {
  padding: 4px 10px;
  min-height: auto;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}
.label.label-primary {
  background: #04a9f5;
  color: #ffffff;
}
.label.label-danger {
  background: #f44236;
  color: #ffffff;
}
.label.label-success {
  background: #1de9b6;
  color: #ffffff;
}
.label.label-warning {
  background: #f4c22b;
  color: #ffffff;
}
.label.label-info {
  background: #3ebfea;
  color: #ffffff;
}

/**====== Label & Badges css end ======**/
/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem 0.75rem;
}
.table thead th {
  border-bottom: 1px solid #eaeaea;
}
.table tbody + tbody {
  border-top: 2px solid #eaeaea;
}

/* Border versions */
.table-bordered {
  border: 1px solid #eaeaea;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #eaeaea;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #37474f;
  border-color: #222c31;
}

.table-dark {
  color: #fff;
  background-color: #37474f;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #222c31;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #334249;
}
.table-dark.table-hover tbody tr:hover {
  background-color: #2d3940;
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important;
}
@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222;
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0;
}
table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0;
}

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #eaeaea;
}
.table-columned > tbody > tr > th {
  border: 0;
}

/**====== Foo-table css end ======**/
/**====== Data Tables css end ======**/
/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: #F5F8F9 !important;
}
.auth-wrapper a,
.auth-wrapper p > a {
  color: #111;
  font-weight: 600;
}
.auth-wrapper .btn-auth-gen .btn-icon {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
}
.auth-wrapper .btn-auth-gen .btn-icon small {
  font-size: 15px;
}
.auth-wrapper .input-group {
  background: transparent;
}
.auth-wrapper .card {
  margin-bottom: 0;
}
.auth-wrapper > div {
  z-index: 5;
}
.auth-wrapper .auth-content {
  position: relative;
  width: 430px;
  padding: 15px;
  z-index: 5;
}
.auth-wrapper .auth-content.multyform, .auth-wrapper .auth-content.subscribe {
  width: 750px;
}
@media only screen and (max-width: 768px) {
  .auth-wrapper {
    max-width: 360px;
  }
}
@media only screen and (max-width: 575px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}
.auth-wrapper .auth-icon {
  font-size: 30px;
}
.auth-wrapper .auth-icon:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auth-wrapper.offline {
  background-image: none;
}
.auth-wrapper.offline:before {
  display: none;
}
.auth-wrapper .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.auth-wrapper .auth-bg .r:first-child {
  top: -100px;
  right: -100px;
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.auth-wrapper .auth-bg .r:last-child {
  left: -100px;
  bottom: -100px;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.auth-wrapper .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
.auth-wrapper .auth-bg .r.s:nth-child(2) {
  top: 150px;
  right: -150px;
  background: #04a9f5;
}
.auth-wrapper .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #1de9b6;
}
.auth-wrapper .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
          animation: floating 7s infinite;
}
.auth-wrapper .auth-bg .r:nth-child(even) {
  -webkit-animation: floating 9s infinite;
          animation: floating 9s infinite;
}



.aut-bg-img-side p {
  line-height: 2;
}

.aut-bg-img .custom-select, .aut-bg-img .form-control,
.aut-bg-img-side .custom-select,
.aut-bg-img-side .form-control {
  background: transparent;
}

.auth-tabs .tab-content {
  overflow: hidden;
  position: relative;
}
.auth-tabs .tab-content .tab-pane > * {
  position: relative;
  z-index: 5;
}
.auth-tabs .tab-content .auth-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 180px;
  transform: rotate(-30deg);
  z-index: 1;
  opacity: 0.2;
}

/* image varient start */
@-webkit-keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
@keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
/**====== Authentication css end ======**/
/* Button variants
 Easily pump out default styles, as well as :hover, :focus, :active,
 and disabled options for all buttons */
/**  =====================
      Button css start
==========================  **/
.btn-theme,
a.btn-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-color: #1de1c2;
  border-radius: 0.25rem;
  padding: 11px 25px;
}
.btn-theme:active, .btn-theme:focus, .btn-theme:not(:disabled):not(.disabled):active,
a.btn-theme:active,
a.btn-theme:focus,
a.btn-theme:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: none;
}
.btn-theme.active,
a.btn-theme.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}

.btn-outline-theme {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme:active, .btn-outline-theme:focus, .btn-outline-theme:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme.active {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%) !important;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 25px;
}
.btn-theme2:active, .btn-theme2:focus, .btn-theme2:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  box-shadow: none;
}
.btn-theme2.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}

.btn-outline-theme2 {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none;
}
.btn-outline-theme2:active, .btn-outline-theme2:focus, .btn-outline-theme2:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme2.active {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%) !important;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: -25px;
  transition: all 0.3s ease-in-out;
}
.btn > i {
  margin-right: 12px;
}
.btn.btn-icon, .btn.drp-icon {
  width: 45px;
  height: 45px;
  padding: 10px 12px;
}
.btn.btn-icon > i, .btn.drp-icon > i {
  margin-right: 0;
}
.btn.drp-icon.dropdown-toggle:after {
  display: none;
}
.btn.drp-icon + .dropdown-menu {
  margin-left: -10px;
}
.btn:active, .btn:focus {
  box-shadow: none;
}
.btn-square {
  border-radius: 0;
}
.btn.disabled {
  cursor: not-allowed;
  opacity: 0.55;
}
.btn-rounded {
  border-radius: 30px;
}

.btn-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 6px 14px;
  font-size: 13px;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 13px 23px;
  font-size: 16px;
}

/* Alternate buttons */
.btn-primary {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:hover {
  color: #fff;
  background-color: #038fcf;
  border-color: #0386c3;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0386c3;
  border-color: #037eb6;
}

.btn-secondary {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #62747d;
  border-color: #5d6e76;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6e76;
  border-color: #57676f;
}

.btn-success {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:hover {
  color: #fff;
  background-color: #14cc9e;
  border-color: #13c095;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #13c095;
  border-color: #12b58c;
}

.btn-info {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:hover {
  color: #fff;
  background-color: #1cb4e6;
  border-color: #18acdd;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #18acdd;
  border-color: #17a3d1;
}

.btn-warning {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ecb50c;
  border-color: #e0ab0c;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #e0ab0c;
  border-color: #d4a20b;
}

.btn-danger {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22012;
  border-color: #ea1b0d;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1b0d;
  border-color: #de190c;
}

.btn-light {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #37474f;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light.disabled, .btn-light:disabled {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #37474f;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}

.btn-dark {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:hover {
  color: #fff;
  background-color: #273338;
  border-color: #222c31;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #222c31;
  border-color: #1d2529;
}

/* outline buttons */
.btn-outline-primary {
  color: #04a9f5;
  background-color: transparent;
  background-image: none;
  border-color: #04a9f5;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #04a9f5;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}

.btn-outline-secondary {
  color: #748892;
  background-color: transparent;
  background-image: none;
  border-color: #748892;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #748892;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-outline-success {
  color: #1de9b6;
  background-color: transparent;
  background-image: none;
  border-color: #1de9b6;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #1de9b6;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}

.btn-outline-info {
  color: #3ebfea;
  background-color: transparent;
  background-image: none;
  border-color: #3ebfea;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3ebfea;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}

.btn-outline-warning {
  color: #f4c22b;
  background-color: transparent;
  background-image: none;
  border-color: #f4c22b;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f4c22b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}

.btn-outline-danger {
  color: #f44236;
  background-color: transparent;
  background-image: none;
  border-color: #f44236;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f44236;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-light {
  color: #f2f2f2;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.btn-outline-dark {
  color: #37474f;
  background-color: transparent;
  background-image: none;
  border-color: #37474f;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #37474f;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}

/* glow buttons */
.btn-glow-primary {
  box-shadow: 0 1px 6px 2px rgba(4, 169, 245, 0.56), 0 6px 11px 2px rgba(4, 169, 245, 0.2);
}
.btn-glow-primary:hover {
  box-shadow: 0 1px 4px 2px rgba(4, 169, 245, 0.56), 0 4px 9px 2px rgba(4, 169, 245, 0.1);
}
.btn-glow-primary:not(:disabled):not(.disabled).active, .btn-glow-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-primary.dropdown-toggle, .btn-glow-primary.active, .btn-glow-primary:focus {
  box-shadow: none;
}

.btn-glow-secondary {
  box-shadow: 0 1px 6px 2px rgba(116, 136, 146, 0.56), 0 6px 11px 2px rgba(116, 136, 146, 0.2);
}
.btn-glow-secondary:hover {
  box-shadow: 0 1px 4px 2px rgba(116, 136, 146, 0.56), 0 4px 9px 2px rgba(116, 136, 146, 0.1);
}
.btn-glow-secondary:not(:disabled):not(.disabled).active, .btn-glow-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-secondary.dropdown-toggle, .btn-glow-secondary.active, .btn-glow-secondary:focus {
  box-shadow: none;
}

.btn-glow-success {
  box-shadow: 0 1px 6px 2px rgba(29, 233, 182, 0.56), 0 6px 11px 2px rgba(29, 233, 182, 0.2);
}
.btn-glow-success:hover {
  box-shadow: 0 1px 4px 2px rgba(29, 233, 182, 0.56), 0 4px 9px 2px rgba(29, 233, 182, 0.1);
}
.btn-glow-success:not(:disabled):not(.disabled).active, .btn-glow-success:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-success.dropdown-toggle, .btn-glow-success.active, .btn-glow-success:focus {
  box-shadow: none;
}

.btn-glow-info {
  box-shadow: 0 1px 6px 2px rgba(62, 191, 234, 0.56), 0 6px 11px 2px rgba(62, 191, 234, 0.2);
}
.btn-glow-info:hover {
  box-shadow: 0 1px 4px 2px rgba(62, 191, 234, 0.56), 0 4px 9px 2px rgba(62, 191, 234, 0.1);
}
.btn-glow-info:not(:disabled):not(.disabled).active, .btn-glow-info:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-info.dropdown-toggle, .btn-glow-info.active, .btn-glow-info:focus {
  box-shadow: none;
}

.btn-glow-warning {
  box-shadow: 0 1px 6px 2px rgba(244, 194, 43, 0.56), 0 6px 11px 2px rgba(244, 194, 43, 0.2);
}
.btn-glow-warning:hover {
  box-shadow: 0 1px 4px 2px rgba(244, 194, 43, 0.56), 0 4px 9px 2px rgba(244, 194, 43, 0.1);
}
.btn-glow-warning:not(:disabled):not(.disabled).active, .btn-glow-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-warning.dropdown-toggle, .btn-glow-warning.active, .btn-glow-warning:focus {
  box-shadow: none;
}

.btn-glow-danger {
  box-shadow: 0 1px 6px 2px rgba(244, 66, 54, 0.56), 0 6px 11px 2px rgba(244, 66, 54, 0.2);
}
.btn-glow-danger:hover {
  box-shadow: 0 1px 4px 2px rgba(244, 66, 54, 0.56), 0 4px 9px 2px rgba(244, 66, 54, 0.1);
}
.btn-glow-danger:not(:disabled):not(.disabled).active, .btn-glow-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-danger.dropdown-toggle, .btn-glow-danger.active, .btn-glow-danger:focus {
  box-shadow: none;
}

.btn-glow-light {
  box-shadow: 0 1px 6px 2px rgba(242, 242, 242, 0.56), 0 6px 11px 2px rgba(242, 242, 242, 0.2);
}
.btn-glow-light:hover {
  box-shadow: 0 1px 4px 2px rgba(242, 242, 242, 0.56), 0 4px 9px 2px rgba(242, 242, 242, 0.1);
}
.btn-glow-light:not(:disabled):not(.disabled).active, .btn-glow-light:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-light.dropdown-toggle, .btn-glow-light.active, .btn-glow-light:focus {
  box-shadow: none;
}

.btn-glow-dark {
  box-shadow: 0 1px 6px 2px rgba(55, 71, 79, 0.56), 0 6px 11px 2px rgba(55, 71, 79, 0.2);
}
.btn-glow-dark:hover {
  box-shadow: 0 1px 4px 2px rgba(55, 71, 79, 0.56), 0 4px 9px 2px rgba(55, 71, 79, 0.1);
}
.btn-glow-dark:not(:disabled):not(.disabled).active, .btn-glow-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-dark.dropdown-toggle, .btn-glow-dark.active, .btn-glow-dark:focus {
  box-shadow: none;
}

.forgotpassLbl {
  color: #47525e;
  font-family: Lato;
  font-size: 35px;
  font-weight: 800;
  line-height: 34px;
  text-align: center;
  display: block;
  margin-bottom: 40px;
}
.add_user {
  background-color: #2561a8;
  border-radius: 4px;
  width: 45%;
  height: 43px;
  cursor: pointer;
  color: #fff;
  border: 0px;
  margin-top: 25px;
}
/* chetan css starts */
body {
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  padding: 0;
  margin: 0;
}
input:focus,
button:focus {
  outline: 0;
}
input[type="checkbox"] {
  display: none;
}
input::-webkit-input-placeholder {
  color: #000;
}
input:-ms-input-placeholder {
  color: #000;
}
input::-ms-input-placeholder {
  color: #000;
}
input::placeholder {
  color: #000;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a:hover {
  text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill::first-line {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}
.butn {
  cursor: pointer;
  color: #fff;
  border: 0px;
  min-width: 150px;
  height: 50px;
  border-radius: 5px;
  background: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  padding: 10px 20px;
  display: block;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}
.flex-parted {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.font-14-500 {
  font-size: 14px;
  font-weight: 500;
}
.color-dark-blue {
  color: #003280;
}
.underlined-link {
  text-decoration: underline;
}
.mt-45 {
  margin-top: 45px;
}
.custom-link {
  color: #009bdd;
  cursor: pointer;
}

/* sign in starts */
.outer-sign-in {
  background: url(/static/media/sign-in-bg.a60e2a98.jpg) no-repeat center center;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.logo-text {
  font-size: 48px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 70px;
  text-transform: uppercase;
  line-height: 1;
}
.sign-in-card {
  background-color: #ffffff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  padding: 75px 70px;
  width: 550px;
  border-radius: 10px;
  margin: auto;
}
.sign-in {
  font-size: 24px;
  letter-spacing: 1px;
  color: #009bdd;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 45px;
  display: block;
  line-height: 1;
}
.input-cntr {
  height: 51px;
  border-radius: 5px;
  border: 1px solid #7a7a7a;
  padding: 10px;
  display: flex;
  /* align-items: center; */
  margin-bottom: 25px;
}
.newcountry .input-cntr {
  padding: 0;
  border: 0;
}
.contry .input-cntr{
  padding: 0;
  border: 0;
}
.contry .select-country{
 width: 100%;
}
.contry .css-yk16xz-control{
  height: 51px;
  width: 100%;
  border: 1px solid #7a7a7a;
}
.newcountry .react-tel-input .form-control {
  height: 51px;
  width: 100%;
  border: 1px solid #7a7a7a;
}
.newcountry .react-tel-input .flag-dropdown{
  background-color: #ffffff;
  border: 1px solid #7a7a7a;
  border-right: none;
}
.newcountry .react-tel-input .country-list{
  left: 75px;
}
.input-icons {
  margin-right: 10px;
  width: 24px;
}
.input-icons img {
  width: 100%;
}
.input-cntr input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
}
.rem-me label {
  font-size: 12px;
  cursor: pointer;
  position: relative;
  padding-left: 22px;
}
.rem-me label::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #7a7a7a;
  position: absolute;
  left: 0;
}
.rem-me input:checked + label::after {
  content: "\2713";
  position: absolute;
  left: 0px;
  width: 16px;
  height: 16px;
  color: #009bdd;
  text-align: center;
  font-weight: 700;
}
/* Forgorpassword OTP Modal Start Kalpesh */
#ForgotPasswordOtp {
  margin: 80px auto 0;
  border-radius: 10px;
  width: 400px;
  padding: 0;
}
#ForgotPasswordOtp .margin input {
  margin: 0;
}
#ForgotPasswordOtp .margin .Verify {
  font-size: 14px;
  color: #003280;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  padding-top: 5px;
  text-decoration: underline;
}
/* sign in ends */

/* header starts */
.header {
  box-shadow: 1.338px 1.486px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 100px;
}
.header-logo {
  font-size: 26px;
  letter-spacing: 1px;
  color: #fefefe;
  font-weight: 700;
  flex: 0 0 264px;
  height: 100%;
  background-color: #009bdd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icons {
  width: 24px;
  line-height: 1;
}
.header-icons img {
  width: 100%;
}
/* .header-left li { */
.header-left a {
  font-size: 18px;
  color: #7a7a7a;
  display: flex;
  align-items: center;
  margin: 0 22px;
}
.header-left a:hover,
.header-left a.active {
  color: #009bdd;
}
.header-left {
  display: flex;
  margin-left: 55px;
}
.header-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header-right {
  display: flex;
  align-items: center;
}
.header-right li {
  margin: 0 15px;
}
.profile-img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  /* position: relative; */
  /* padding-right: 20px; */
  box-sizing: content-box;
  cursor: pointer;
  overflow: hidden;
}
/* .profile-img::after { */
.profile-img-arrow {
  /* content: ""; */
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #009bdd;
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* right: 0; */
  margin-left: 8px;
  cursor: pointer;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-profile {
  padding-right: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.notification-count {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ff7240;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  position: absolute;
  bottom: 100%;
  left: 5px;
  line-height: 28px;
}
/* header ends */

.cards-icon {
  width: 35px;
}
.read-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #009bdd;
  border-radius: 50%;
  left: -7px;
  top: 50%;
  transform: translateY(-50%);
}
.disable-otp {
  opacity: 0.5;
  pointer-events: none;
}

/* responsive starts */
@media screen and (max-width: 1500px) {
  /* sign in starts */
  .logo-text {
    font-size: 36px;
    margin-bottom: 50px;
  }
  .sign-in-card {
    padding: 45px 40px;
    width: 500px;
  }
  .sign-in {
    margin-bottom: 30px;
  }
  .mt-45 {
    margin-top: 30px;
  }
  .butn {
    height: 45px;
    font-size: 16px;
    min-width: 140px;
  }
  .input-cntr {
    margin-bottom: 20px;
  }
  /* sign in ends */

  /* header starts */
  .header {
    height: 60px;
  }
  .header-logo {
    font-size: 20px;
    flex: 0 0 180px;
  }
  .header-icons {
    width: 20px;
  }
  .header-left a {
    font-size: 16px;
    margin: 0 15px;
  }
  .header-left {
    margin-left: 20px;
  }
  .header-right li {
    margin: 0 10px;
  }
  .user-profile {
    padding-right: 20px;
    margin-left: 15px;
  }
  .profile-img {
    width: 40px;
    height: 40px;
    /* padding-right: 18px; */
  }
  /* .profile-img::after { */
  .profile-img-arrow {
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 8px;
  }
  .notification-count {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    left: 7px;
    bottom: 75%;
  }
  /* header ends */
}

@media screen and (max-width: 576px) {
  /* sign in starts */
  .sign-in-card {
    width: 100%;
    padding: 40px 30px;
  }
  .logo-text {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .sign-in {
    font-size: 20px;
  }
  /* sign in ends */
}

@media screen and (max-width: 420px) {
  /* sign in starts */
  .outer-sign-in {
    padding-right: 20px;
    padding-left: 20px;
  }
  .sign-in-card {
    padding-right: 15px;
    padding-left: 15px;
  }
  .input-cntr input {
    font-size: 14px;
  }
  .dashboard .card {
    margin-bottom: 15px;
  }
  /* sign in ends */
}
/* responsive ends */

/* chetan css ends */

/* Kalpesh CSS Start Dashboard */
.dashboard,
.Merchantdashboard,
.Notification,
.Userdashboard,
.userpayment {
  padding: 25px 30px;
  margin: 10px 0 0 0;
  background: #fff;
}
.dash {
  font-size: 24px;
  letter-spacing: 1px;
  color: #009bdd;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  line-height: 1;
}
.dashboard .card {
  border: 0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
}
.back1 {
  background: #d5ffda;
}
.back2 {
  background: #ffe4db;
}
.back3 {
  background: #feffd0;
}
.back4 {
  background: #ddfbff;
}
.image {
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
}
.image img {
  width: 40px;
  height: 40px;
}
.text {
  margin-top: 20px;
}
.text label {
  display: block;
}
.text label:nth-child(1) {
  color: #47525e;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
}
.text label:nth-child(2) {
  color: #009bdd;
  font-size: 24px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}
/* Kalpesh CSS End Dashboard */

/* Kalpesh CSS for HeaderUser Modal Start */
#HeaderUserModal {
  margin-right: 40px;
  margin-top: 45px;
  width: 310px;
  border-radius: 10px;
}
#HeaderUserModal .react-responsive-modal-closeButton {
  display: none;
}
#HeaderUserModal .usericon {
  width: 40px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #47525e;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
#HeaderUserModal .usericon img {
  /* width: 20px;
  height: 20px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#HeaderUserModal .usertext {
  display: inline-block;
  margin-left: 15px;
}
#HeaderUserModal .usertext label {
  display: block;
}
#HeaderUserModal .usertext label img {
  width: 15px;
  height: 15px;
  margin: 0 0 3px 10px;
  cursor: pointer;
}
#HeaderUserModal .usertext label a:focus {
  outline: 0;
}
#HeaderUserModal .usertext label:nth-child(1) {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #47525e;
  line-height: 20px;
  text-transform: capitalize;
}
#HeaderUserModal .usertext label:nth-child(2) {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #47525e;
  line-height: 25px;
  text-transform: capitalize;
}
#HeaderUserModal .Logout {
  text-align: center;
  margin: 25px auto 0;
}
#HeaderUserModal .Logout button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  text-align: center;
  margin: 0;
  padding: 10px 60px;
  text-transform: capitalize;
}
/* Kalpesh CSS for HeaderUser Modal End */

/* Kalpesh CSS for Profile Page Start */
.mainprofile {
  background: #fff;
  padding: 30px 35px;
}
.profile {
  border-radius: 5px;
  background-color: rgb(245, 248, 249);
  border-radius: 6px;
}
.profile .card {
  height: auto;
  border: 0;
  background-color: rgb(245, 248, 249);
}
.profile .prof {
  margin: auto;
}
.mainprofile h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  padding: 0 0 20px 0;
  color: #009bdd;
  text-transform: capitalize;
}
.profile .closeicon {
  position: relative;
}
.profile .closeicon img {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 6px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  cursor: pointer;
}
.profile .profileimg {
  border: 1px solid #eaeced;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.profile .profileimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile .upload {
  margin: 40px auto 0;
  position: relative;
}
.profile .uploadicon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  cursor: pointer;
}
.profile .uploadicon img {
  width: 15px;
  height: 15px;
}
.formdata {
  padding-top: 25px;
}
.formdata label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  padding: 0 0 0 10px;
  opacity: 0.8;
}
.formdata input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  /* font-family: Lato; */
  color: #47525e;
}
.formdata input:disabled {
  color: #c5bcbc;
  cursor: not-allowed;
}
.formdata input::-webkit-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
.formdata input:-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
.formdata input::-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
.formdata input::placeholder {
  color: #47525e;
  opacity: 0.7;
}
.save {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin: 0 0 20px 0;
}
.save button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  text-align: center;
  margin: 0;
  padding: 10px 50px;
  text-transform: capitalize;
  height: 40px;
  font-family: Lato;
  line-height: 10px;
}
.btn:hover {
  color: #fff;
}
.save p {
  margin: 0 0;
  color: #009bdd;
  font-size: 12px;
  font-weight: 600;
  padding: 20px 0;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
}
/* Kalpesh CSS for Profile Page End */

/* UserManagement,MerchantManagemnt CSS Start UserManagement  */
.userManagement,
.merchManagement,
.usermanadetail,
.mermanadetail,
.adminsetting,
.MerTraHistory,
.MerSaleReport,
.merSubsc,
.merPlanPayment,
.UserTrans,
.instalmentDetail {
  background: #fff;
  padding: 30px 35px;
  margin-top: 10px;
}
.userManagement .Usermana,
.merchManagement .Usermana,
.usermanadetail .Usermana,
.mermanadetail .Usermana,
.adminsetting .Usermana,
.MerTraHistory .Usermana,
.MerSaleReport .Usermana,
.merSubsc .Usermana,
.merPlanPayment .Usermana,
.UserTrans .Usermana,
.instalmentDetail .Usermana {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  padding: 0 0 20px 0;
  color: #009bdd;
  text-transform: capitalize;
  display: inline;
}
.userManagement .exfilter,
.merchManagement .exfilter,
.MerTraHistory .exfilter,
.MerSaleReport .exfilter,
.UserTrans .exfilter,
.instalmentDetail .exfilter {
  float: right;
  margin-bottom: 25px;
}
.exfilter label img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: bottom;
}

.exfilter a img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.exfilter label .WhDrop {
  width: 13px;
  height: 13px;
  margin-left: 15px;
  margin-right: 0;
  vertical-align: baseline;
  /* transform: rotate(60deg); */
}
.exfilter label .WhDrop1 {
  width: 13px;
  height: 13px;
  margin-left: 15px;
  margin-right: 0;
  vertical-align: baseline;
  transform: rotate(180deg);
}
.exfilter label:first-child {
  background-image: #fff;
  color: #009bdd;
  border-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  border: 1px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.exfilter a:first-child {
  background-image: #fff;
  color: #009bdd;
  border-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  border: 1px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.exfilter label:last-child {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}
.userManagement .back,
.merchManagement .back,
.paymentmana .back,
.MerTraHistory .back,
.MerSaleReport .back,
.UserTrans .back {
  background-color: rgb(245, 248, 249);
  border-radius: 5px;
  padding: 25px 0 20px 0;
}
.userManagement .back input,
.merchManagement .back input,
.paymentmana .back input,
.MerTraHistory .back input,
.MerSaleReport .back input,
.UserTrans .back input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 45px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #47525e;
}
.userManagement .back input::-webkit-input-placeholder, .merchManagement .back input::-webkit-input-placeholder, .paymentmana .back input::-webkit-input-placeholder, .MerTraHistory .back input::-webkit-input-placeholder, .MerSaleReport .back input::-webkit-input-placeholder, .UserTrans .back input::-webkit-input-placeholder, .userManagement .back select::-webkit-input-placeholder, .merchManagement .back select::-webkit-input-placeholder, .paymentmana .back select::-webkit-input-placeholder, .MerTraHistory .back select::-webkit-input-placeholder, .MerSaleReport .back select::-webkit-input-placeholder, .UserTrans .back select::-webkit-input-placeholder {
  color: #000;
}
.userManagement .back input:-ms-input-placeholder, .merchManagement .back input:-ms-input-placeholder, .paymentmana .back input:-ms-input-placeholder, .MerTraHistory .back input:-ms-input-placeholder, .MerSaleReport .back input:-ms-input-placeholder, .UserTrans .back input:-ms-input-placeholder, .userManagement .back select:-ms-input-placeholder, .merchManagement .back select:-ms-input-placeholder, .paymentmana .back select:-ms-input-placeholder, .MerTraHistory .back select:-ms-input-placeholder, .MerSaleReport .back select:-ms-input-placeholder, .UserTrans .back select:-ms-input-placeholder {
  color: #000;
}
.userManagement .back input::-ms-input-placeholder, .merchManagement .back input::-ms-input-placeholder, .paymentmana .back input::-ms-input-placeholder, .MerTraHistory .back input::-ms-input-placeholder, .MerSaleReport .back input::-ms-input-placeholder, .UserTrans .back input::-ms-input-placeholder, .userManagement .back select::-ms-input-placeholder, .merchManagement .back select::-ms-input-placeholder, .paymentmana .back select::-ms-input-placeholder, .MerTraHistory .back select::-ms-input-placeholder, .MerSaleReport .back select::-ms-input-placeholder, .UserTrans .back select::-ms-input-placeholder {
  color: #000;
}
.userManagement .back input::placeholder,
.merchManagement .back input::placeholder,
.paymentmana .back input::placeholder,
.MerTraHistory .back input::placeholder,
.MerSaleReport .back input::placeholder,
.UserTrans .back input::placeholder,
.userManagement .back select::placeholder,
.merchManagement .back select::placeholder,
.paymentmana .back select::placeholder,
.MerTraHistory .back select::placeholder,
.MerSaleReport .back select::placeholder,
.UserTrans .back select::placeholder {
  color: #000;
}
.userManagement .back .calendar,
.merchManagement .back .calendar,
.paymentmana .back .calendar,
.MerTraHistory .back .calendar,
.MerSaleReport .back .calendar,
.UserTrans .back .calendar {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px 0;
  width: 100%;
  font-size: 19px;
  height: 45px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #47525e;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCwYNCSmIEi2PAAABWUlEQVRIx+2TvUoDURSEv7O75AEUNFoEWRAEKwuNlailEG8KU1mkUvAJtNKUPoOtiMU20RRqY9TGBAQtrQI2sUtlkQ0sxyIxQoLujzaCUx3YmTlz596FPkzVqKkSgkGWFSYIgwOQW7XmQTOCZszu94IPlmjwULkGKaQ6J2wk2y7e2KY9vc9O4vyzb4GYV9LUU2tey1RZ5uZsJazELqsw0rlggabFOHDlteIu91pcAhMOAqIAcqp1aYSeu88SVRDn81P5KMrmQZYYBW2Ebx6GuuL23oG4uPENBOgZ/DiBdVwuxTfIl/Tgt/4FgNyMvQfBYeU56tTV9RPYaS1q0U5HnwYSBG3rBYJ29KkHo0bzCSqEfMmo0f8S/2SJgwaxS/wyQczqhg0qNaaiTMNH8EFHEvU3CvgOdZbYNkozpnxSt4B7WZ+TGqlkV0hHstb5oy5yhx9b7OutZMtP72qJEatTItOKAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTExLTA2VDEzOjA5OjQxKzAwOjAwE2UXNAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMS0wNlQxMzowOTo0MSswMDowMGI4r4gAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat right 15px center;
  line-height: 14px;
  background-size: 15px 15px;
}
.userManagement .calendar input,
.UserTrans .calendar input,
.MerTraHistory .calendar input,
.merchManagement .calendar input,
.paymentmana .calendar input,
.MerSaleReport .calendar input {
  margin: 0;
  border: 0;
}
.ant-picker-focused {
  box-shadow: none;
}
.userManagement .calendar .ant-picker-suffix,
.UserTrans .calendar .ant-picker-suffix,
.MerTraHistory .calendar .ant-picker-suffix,
.merchManagement .calendar .ant-picker-suffix,
.paymentmana
  .calendar
  .ant-picker-suffix
  .MerSaleReport
  .calendar
  .ant-picker-suffix {
  display: none;
}
.userManagement .back .calendar .ant-picker-active-bar,
.userManagement .back .calendar .ant-picker-active-bar,
.UserTrans .back .calendar .ant-picker-active-bar,
.MerTraHistory .back .calendar .ant-picker-active-bar,
.merchManagement .back .calendar .ant-picker-active-bar,
.paymentmana .back .calendar .ant-picker-active-bar,
.MerSaleReport .back .calendar .ant-picker-active-bar {
  margin-left: 0px !important;
}
.userManagement .back select,
.merchManagement .back select,
.paymentmana .back select,
.MerTraHistory .back select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 45px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #47525e;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASxJREFUeNq8lUFqwkAUQEdxVbpzW7B05aqXKd6goTsX7V66FIorVyUb7+BGD1APUIQKSlGkF5BSMkRM38CfkIZmGEM08DJhMvNf8v8nqSVJos5xNIIguGJsm+sTOfawMME13MEtXFYs+YZ36FnRB7SgCRcVSX7gS2LrOqcdjGACS4grkMQSy8QchWG4s3Uxsld4gxe4gXpJyQE28AxzJCZjf4KZiTncw1o2lJGsJUYqUf88tZa8Psh4OFKS7s1KVEF6tDxVV8bYsybpnrxEOepg09iHT4gckkjW9PPp8hFZ2QzGsCqQRXLPrJkVSZRHZ2Vbf5NLYyxzaQs7P0Ee+betb2rwCNcyv4UhTF1vcozIpnEqXTWQuSdXTcqKsg3Sybyp9t1cO9dv4leAAQCnZXX+jQEPIwAAAABJRU5ErkJggg==) no-repeat right 15px center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  line-height: 20px;
  background-size: 12px 12px;
}
.userManagement .back select:focus,
.merchManagement .back select:focus,
.paymentmana .back select:focus,
.MerTraHistory .back select:focus {
  outline: 0;
}
.userManagement .back .Totalamount,
.merchManagement .back .Totalamount {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  color: #4f4f4f;
  padding-left: 10px;
  margin-bottom: 15px;
}
/* .progress {
  height: 2px;
} */
.slidecontainer {
  width: 100%;
  margin-top: -15px;
}
.userManagement .back .search,
.merchManagement .back .search,
.paymentmana .back .search,
.MerTraHistory .back .search,
.MerSaleReport .back .search,
.UserTrans .back .search {
  text-align: center;
}
.userManagement .back .search button,
.merchManagement .back .search button,
.paymentmana .back .search button,
.MerTraHistory .back .search button,
.MerSaleReport .back .search button,
.UserTrans .back .search button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 25px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
  margin: 20px 0 0 0;
}
.usmanatable,
.mermanatable,
.paymenttable,
.roletable,
.instantpaytable,
.subscriptiontable,
.alerttable,
.Merchantdashtable,
.MerchantrHistorytable,
.notifitable,
.Userdashtable,
.userpaymenttable {
  margin-top: 25px;
}
.usmanatable .ant-table-thead > tr > th,
.mermanatable .ant-table-thead > tr > th,
.usermanadetailtable .ant-table-thead > tr > th,
.merchmanadetailtable .ant-table-thead > tr > th,
.paymenttable .ant-table-thead > tr > th {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 15px 15px;
}
.usmanatable tr.ant-table-row:nth-child(odd),
.mermanatable tr.ant-table-row:nth-child(odd),
.usermanadetailtable tr.ant-table-row:nth-child(odd),
.merchmanadetailtable tr.ant-table-row:nth-child(odd),
.splitpaymenttable tr.ant-table-row:nth-child(odd),
.paymenttable tr.ant-table-row:nth-child(odd),
.roletable tr.ant-table-row:nth-child(odd),
.instantpaytable tr.ant-table-row:nth-child(odd),
.subscriptiontable tr.ant-table-row:nth-child(odd),
.alerttable tr.ant-table-row:nth-child(odd),
.Merchantdashtable tr.ant-table-row:nth-child(odd),
.MerchantrHistorytable tr.ant-table-row:nth-child(odd),
.merroletable tr.ant-table-row:nth-child(odd),
.merusermanatable tr.ant-table-row:nth-child(odd),
.notifitable tr.ant-table-row:nth-child(odd),
.MerSaleRepTable tr.ant-table-row:nth-child(odd),
.Userdashtable tr.ant-table-row:nth-child(odd),
.userpaymenttable tr.ant-table-row:nth-child(odd),
.instalmentDetailtable tr.ant-table-row:nth-child(odd) {
  background: #fff;
}
.usmanatable tr.ant-table-row:nth-child(even),
.mermanatable tr.ant-table-row:nth-child(even),
.usermanadetailtable tr.ant-table-row:nth-child(even),
.merchmanadetailtable tr.ant-table-row:nth-child(even),
.splitpaymenttable tr.ant-table-row:nth-child(even),
.paymenttable tr.ant-table-row:nth-child(even),
.roletable tr.ant-table-row:nth-child(even),
.instantpaytable tr.ant-table-row:nth-child(even),
.subscriptiontable tr.ant-table-row:nth-child(even),
.alerttable tr.ant-table-row:nth-child(even),
.Merchantdashtable tr.ant-table-row:nth-child(even),
.MerchantrHistorytable tr.ant-table-row:nth-child(even),
.merroletable tr.ant-table-row:nth-child(even),
.merusermanatable tr.ant-table-row:nth-child(even),
.notifitable tr.ant-table-row:nth-child(even),
.MerSaleRepTable tr.ant-table-row:nth-child(even),
.Userdashtable tr.ant-table-row:nth-child(even),
.userpaymenttable tr.ant-table-row:nth-child(even),
.instalmentDetailtable tr.ant-table-row:nth-child(even) {
  background: #f3fbff;
}
.instalmentDetailtable tr.ant-table-row.disabled-row {
  background: #eee;
  pointer-events: none;
}
.usmanatable .ant-table-container table > thead > tr:first-child th:first-child,
.mermanatable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.usermanadetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.merchmanadetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.splitpaymenttable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.paymenttable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.roletable .ant-table-container table > thead > tr:first-child th:first-child,
.instantpaytable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.subscriptiontable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.alerttable .ant-table-container table > thead > tr:first-child th:first-child,
.Merchantdashtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.MerchantrHistorytable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.merroletable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.merusermanatable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.notifitable .ant-table-container table > thead > tr:first-child th:first-child,
.MerSaleRepTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.Userdashtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.userpaymenttable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.instalmentDetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 10px;
}
.usmanatable .ant-table-container table > thead > tr:first-child th:last-child,
.mermanatable .ant-table-container table > thead > tr:first-child th:last-child,
.usermanadetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.merchmanadetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.splitpaymenttable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.paymenttable .ant-table-container table > thead > tr:first-child th:last-child,
.roletable .ant-table-container table > thead > tr:first-child th:last-child,
.instantpaytable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.subscriptiontable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.alerttable .ant-table-container table > thead > tr:first-child th:last-child,
.Merchantdashtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.MerchantrHistorytable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.merroletable .ant-table-container table > thead > tr:first-child th:last-child,
.merusermanatable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.notifitable .ant-table-container table > thead > tr:first-child th:last-child,
.MerSaleRepTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.Userdashtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.userpaymenttable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.instalmentDetailtable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 10px;
}
.usmanatable .ant-table-tbody > tr > td,
.mermanatable .ant-table-tbody > tr > td,
.usermanadetailtable .ant-table-tbody > tr > td,
.merchmanadetailtable .ant-table-tbody > tr > td,
.paymenttable .ant-table-tbody > tr > td {
  font-size: 13px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 20px;
  text-transform: capitalize;
  opacity: 0.8;
}
.mermanatable .amazontext img,
.splitpayment .amazontext img,
.paymenttable .amazontext img,
.roletable .amazontext img,
.instantpaytable .amazontext img,
.subscriptiontable .amazontext img,
.alerttable .amazontext img,
.Merchantdashtable .amazontext img,
.MerchantrHistorytable .amazontext img,
.merroletable .amazontext img,
.merusermanatable .amazontext img,
.Userdashtable .amazontext img,
.instalmentDetailtable .amazontext img {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 7px;
  cursor: pointer;
}
.usmanatable .totalTran,
.mermanatable .totalTran {
  text-align: center;
}
.usmanatable .totalAmoun,
.mermanatable .totalAmoun {
  text-align: center;
}
.usmanatable .status .switch,
.mermanatable .status .switch,
.splitpaymenttable .status .switch,
.roletable .status .switch,
.instantpaytable .status .switch,
.subscriptiontable .status .switch,
.alerttable .status .switch,
.merroletable .status .switch,
.merusermanatable .status .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}
.usmanatable .status .switch input,
.mermanatable .status .switch input,
.splitpaymenttable .status .switch input,
.roletable .status .switch input,
.instantpaytable .status .switch input,
.subscriptiontable .status .switch input,
.alerttable .status .switch input,
.merroletable .status .switch input,
.merusermanatable .status .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.usmanatable .status .slider,
.mermanatable .status .slider,
.splitpaymenttable .status .slider,
.roletable .status .slider,
.instantpaytable .status .slider,
.subscriptiontable .status .slider,
.alerttable .status .slider,
.merroletable .status .slider,
.merusermanatable .status .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #797979;
  transition: 0.4s;
}
.usmanatable .status .slider:before,
.mermanatable .status .slider:before,
.splitpaymenttable .status .slider:before,
.roletable .status .slider:before,
.instantpaytable .status .slider:before,
.subscriptiontable .status .slider:before,
.alerttable .status .slider:before,
.merroletable .status .slider:before,
.merusermanatable .status .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.usmanatable .status input:checked + .slider,
.mermanatable .status input:checked + .slider,
.splitpaymenttable .status input:checked + .slider,
.roletable .status input:checked + .slider,
.instantpaytable .status input:checked + .slider,
.subscriptiontable .status input:checked + .slider,
.alerttable .status input:checked + .slider,
.merroletable .status input:checked + .slider,
.merusermanatable .status input:checked + .slider {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
}
.usmanatable .status input:focus + .slider,
.mermanatable .status input:focus + .slider,
.splitpaymenttable .status input:focus + .slider,
.roletable .status input:focus + .slider,
.instantpaytable .status input:focus + .slider,
.subscriptiontable .status input:focus + .slider,
.alerttable .status input:focus + .slider,
.merroletable .status input:focus + .slider,
.merusermanatable .status input:focus + .slider {
  box-shadow: 0 0 1px #009bdd;
}
.usmanatable .status input:checked + .slider:before,
.mermanatable .status input:checked + .slider:before,
.splitpaymenttable .status input:checked + .slider:before,
.roletable .status input:checked + .slider:before,
.instantpaytable .status input:checked + .slider:before,
.subscriptiontable .status input:checked + .slider:before,
.alerttable .status input:checked + .slider:before,
.merroletable .status input:checked + .slider:before,
.merusermanatable .status input:checked + .slider:before {
  transform: translateX(23px);
}
.usmanatable .status .slider.round,
.mermanatable .status .slider.round,
.splitpaymenttable .status .slider.round,
.roletable .status .slider.round,
.instantpaytable .status .slider.round,
.subscriptiontable .status .slider.round,
.alerttable .status .slider.round,
.merroletable .status .slider.round,
.merusermanatable .status .slider.round {
  border-radius: 34px;
}
.usmanatable .status .slider.round:before,
.mermanatable .status .slider.round:before,
.splitpaymenttable .status .slider.round:before,
.roletable .status .slider.round:before,
.instantpaytable .status .slider.round:before,
.subscriptiontable .status .slider.round:before,
.alerttable .status .slider.round:before,
.merroletable .status .slider.round:before,
.merusermanatable .status .slider.round:before {
  border-radius: 50%;
}
.usmanatable .action .editdele,
.mermanatable .action .editdele,
.paymenttable .action .editdele,
.roletable .action .editdele,
.instantpaytable .action .editdele,
.subscriptiontable .action .editdele,
.alerttable .action .editdele,
.merroletable .action .editdele,
.merusermanatable .action .editdele,
.userpaymenttable .action .editdele {
  display: flex;
  align-items: center;
}
.usmanatable .action .editdele img,
.mermanatable .action .editdele img,
.splitpaymenttable .action .editdele img,
.paymenttable .action .editdele img,
.roletable .action .editdele img,
.instantpaytable .action .editdele img,
.subscriptiontable .action .editdele img,
.alerttable .action .editdele img,
.merroletable .action .editdele img,
.merusermanatable .action .editdele img,
.userpaymenttable .action .editdele img {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 0 10px;
}
.mermanatable .approve button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}
.usermanadetailtable .against,
.merchmanadetailtable .against {
  color: #4dc0ff;
}
.usermanadetailtable .pending,
.merchmanadetailtable .pending,
.Userdashtable .pending {
  color: #4dc0ff;
}
.usermanadetailtable .failed,
.merchmanadetailtable .failed,
.paymenttable .failed,
.Merchantdashtable .failed,
.MerchantrHistorytable .failed,
.MerSaleRepTable .failed,
.Userdashtable .failed,
.instalmentDetailtable .failed {
  color: #f9777a;
}
.usermanadetailtable .success,
.merchmanadetailtable .success,
.paymenttable .success,
.Merchantdashtable .success,
.MerchantrHistorytable .success,
.MerSaleRepTable .success,
.Userdashtable .success,
.instalmentDetailtable .success {
  color: #95dc79;
}
/* Pagination */
.pagination {
  justify-content: center;
  margin-top: 30px;
  position: relative;
}
.pagination ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.pagination ul li {
  margin: 0 0;
  padding: 5px 12px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.pagination ul li.active {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
}
.pagination ul li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination ul li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination .selectopt select {
  padding: 6px 2px;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-left: 10px;
}
.pagination .selectopt {
  position: absolute;
  right: 0;
}
/* Edit User Modal */
#EditUserModal {
  margin: 100px auto 0;
  border-radius: 10px;
  width: 550px;
}
#EditUserMerModal {
  margin: 40px auto 0;
  border-radius: 10px;
  width: 550px;
}
#EditUserModal label,
#EditUserMerModal label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  opacity: 0.8;
}
#EditUserModal input,
#EditUserMerModal input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#EditUserMerModal select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#EditUserMerModal select:focus {
  outline: 0;
}
#EditUserModal input::-webkit-input-placeholder, #EditUserMerModal input::-webkit-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserModal input:-ms-input-placeholder, #EditUserMerModal input:-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserModal input::-ms-input-placeholder, #EditUserMerModal input::-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserModal input::placeholder,
#EditUserMerModal input::placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserModal .switch,
#EditUserMerModal .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 5px 0 0 10px;
}
#EditUserModal .switch input,
#EditUserMerModal .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
#EditUserModal .slider,
#EditUserMerModal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #797979;
  transition: 0.4s;
}
#EditUserModal .slider:before,
#EditUserMerModal .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
#EditUserModal input:checked + .slider,
#EditUserMerModal input:checked + .slider {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
}
#EditUserModal input:focus + .slider,
#EditUserMerModal input:focus + .slider {
  box-shadow: 0 0 1px #009bdd;
}
#EditUserModal input:checked + .slider:before,
#EditUserMerModal input:checked + .slider:before {
  transform: translateX(23px);
}
#EditUserModal .slider.round,
#EditUserMerModal .slider.round {
  border-radius: 34px;
}
#EditUserModal .slider.round:before,
#EditUserMerModal .slider.round:before {
  border-radius: 50%;
}
#EditUserModal .active,
#EditUserMerModal .active {
  margin: 5px 0 0 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  opacity: 0.8;
  vertical-align: top;
}
#EditUserModal .react-responsive-modal-closeButton,
#EditUserMerModal .react-responsive-modal-closeButton {
  display: none;
}
#EditUserModal .eduser,
#EditUserMerModal .eduser {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 30px;
}
#EditUserModal .edituser,
#EditUserMerModal .edituser {
  padding: 20px 0;
}
#EditUserModal .closeicon,
#EditUserMerModal .closeicon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  float: right;
}
#EditUserModal .Editbtn,
#EditUserMerModal .Editbtn {
  text-align: center;
}
#EditUserModal .Editbtn button,
#EditUserMerModal .Editbtn button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 30px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
  width: 170px;
  height: 40px;
  margin: 15px 0 0 0;
}
/*User Delete popover */
.deletepopover h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
}
.deletepopover .delete {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
  margin-right: 10px;
}
.deletepopover {
  padding: 8px 5px;
}
.deletepopover .subsc label:first-child {
  width: 140px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.deletepopover .subsc label:last-child {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.ant-popover-inner {
  border-radius: 10px !important;
}
/* Kalpesh CSS Start UserManagement */

/* User Management Detail,Merchant Management Detail Css Start Kalpesh */
.usdetailtext {
  margin-bottom: 15px;
}
.usdetailtext label:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
  display: block;
  opacity: 0.9;
}
.usdetailtext label:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 20px;
  text-transform: capitalize;
  display: block;
}
.usermanadetail .exfilter,
.mermanadetail .exfilter {
  float: right;
  margin-bottom: 15px;
}
.usermanadetail .exfilter label,
.mermanadetail .exfilter label {
  background-image: #fff;
  color: #009bdd;
  border-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  border: 1px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  background: #fff;
}

.usermanadetail .exfilter a,
.mermanadetail .exfilter a {
  background-image: #fff;
  color: #009bdd;
  border-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  border: 1px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  background: #fff;
}

.usermanadetail .exfilter input,
.mermanadetail .exfilter input {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCwcJKTomnYdKAAACo0lEQVRIx53VT0hUURTH8e99o+OfDDT/JJOlhqIVtTBctDFLTRz1XRW0RbTTlbpokwj9gaiQNi4MN+0lssx5NmiQGoFKjSQVpULtchQsJQ3/z7stIn1jMzp6du/c+/vAOQ/eE2yr0riwElUmTnCYBGaZ5jM96qWxSJAS1gdncvgN6rD/d2tZtfla3PO7ALKWVmJQjGBoQ5p3ZV4khDvM8+icBeZFXfezoEB+WGyragBeqWZjdPslPU+0cA4l7nXfQgWcRD6USm7IpmCTImST9Eml3w4cr5dKrksnO5Z+RZrSrKjx79qg7IhmEE6jq2NnYPJTFuIChWcefVne6mpgu0s0fa52di3jDqPErfoNqlUmcRVTNe0eB5S6DqK+OsoC+CQ2hoyPIQEYg0xwYOWSBRBOEK7Q4gC4QCu17iADTE/oeeEBMqyAA/DuAfACDisQDSyFDpi/gRgrMAMqeQ87cAAzVsALIm0PQJr/yBrDgHMPQCnwxgKobkAviQgtXRFLIWhuC5AzwjeRaG8McYXNRDH8fHyrI0Be5jHzvtMvpnaLl2drY0SaBT0D1h3gesIQcWFGefTO8epDNoNInlrjoAFK1TClcmw9lfHB47pjvU9l8tVe69/XAAyvqTOnLvre6XmB49IpRlUuMLtq+p9sflT1DNHNKcBN29rr3tXNuaNFobhGPqgPOEQiw2slvQsBANAPips0EAUsCo+aEnNmvDhGLlHAknqQfH82y+wnibf24s5fAQCAqpSNRiHJ8mtO0mG29/wAkCfpJ1l41ov//SW2AX+rLNOWjYNYscC0Ntr1fdur7Mch3ocXdc4FBXauskzbACnqQ0RR5+y+AKhIUwOkM24r6JreFwBVqRsD4jgTqmCfAFSl+gZJZ2zfAMijuPn5B5HB2coya115AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTExLTA3VDA5OjQxOjU4KzAwOjAw4GF3zgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMS0wN1QwOTo0MTo1OCswMDowMJE8z3IAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat left 15px center;
  background-size: 15px 15px;
  color: #4f4f4f;
  border: 1px solid #ededed;
  padding: 10px 15px 10px 40px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  opacity: 0.7;
}
/* User Management Detail,Merchant Management Detail Css End Kalpesh */
/* AdminSettings CSS Start Kalpesh */
.setting .card {
  background: #f4f6fd;
  border: 0;
  /* box-shadow: 0 0 5px #F4F6FD; */
  padding: 20px;
  display: block;
  cursor: pointer;
  color: #4a4a4a;
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
}
.setting .card:hover,
.setting .card:focus {
  background: #009bdd;
  color: #fff;
}
.setting .card .totalmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.setting .card .total {
  background: #fff;
  width: 60px;
  height: 60px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
  flex: 0 0 60px;
  box-shadow: 0px 0px 10px #ccc;
}
.setting .card .total img {
  width: 30px;
  height: 30px;
}
.setting .card label {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.setting .card p {
  font-size: 12px;
  margin: 20px 0 0 0;
  line-height: 15px;
}
/* AdminSettings CSS End Kalpesh */
/* Split payment Management CSS Start Kalpesh */
.splitpayment,
.paymentmana,
.roles,
.instantpay,
.subscription,
.alerttemplate,
.merrole,
.merusermana {
  background: #fff;
  padding: 30px 35px;
}
.splitpayment .Usermana,
.paymentmana .Usermana,
.roles .Usermana,
.instantpay .Usermana,
.subscription .Usermana,
.alerttemplate .Usermana,
.merrole .Usermana,
.merusermana .Usermana,
.Notification .Usermana,
.userpayment .Usermana {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
  /* padding: 0 0 15px 0; */
  color: #009bdd;
  text-transform: capitalize;
  display: inline;
  margin: 0;
}
.splitpayment .exfilter,
.paymentmana .exfilter,
.roles .exfilter,
.instantpay .exfilter,
.subscription .exfilter,
.alerttemplate .exfilter,
.merrole .exfilter,
.merusermana .exfilter {
  float: right;
  margin-bottom: 25px;
}
.splitpayment .exfilter input,
.roles .exfilter input,
.instantpay .exfilter input,
.subscription .exfilter input,
.alerttemplate .exfilter input,
.merrole .exfilter input,
.merusermana .exfilter input,
.instalmentDetail .exfilter input {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCwcJKTomnYdKAAACo0lEQVRIx53VT0hUURTH8e99o+OfDDT/JJOlhqIVtTBctDFLTRz1XRW0RbTTlbpokwj9gaiQNi4MN+0lssx5NmiQGoFKjSQVpULtchQsJQ3/z7stIn1jMzp6du/c+/vAOQ/eE2yr0riwElUmTnCYBGaZ5jM96qWxSJAS1gdncvgN6rD/d2tZtfla3PO7ALKWVmJQjGBoQ5p3ZV4khDvM8+icBeZFXfezoEB+WGyragBeqWZjdPslPU+0cA4l7nXfQgWcRD6USm7IpmCTImST9Eml3w4cr5dKrksnO5Z+RZrSrKjx79qg7IhmEE6jq2NnYPJTFuIChWcefVne6mpgu0s0fa52di3jDqPErfoNqlUmcRVTNe0eB5S6DqK+OsoC+CQ2hoyPIQEYg0xwYOWSBRBOEK7Q4gC4QCu17iADTE/oeeEBMqyAA/DuAfACDisQDSyFDpi/gRgrMAMqeQ87cAAzVsALIm0PQJr/yBrDgHMPQCnwxgKobkAviQgtXRFLIWhuC5AzwjeRaG8McYXNRDH8fHyrI0Be5jHzvtMvpnaLl2drY0SaBT0D1h3gesIQcWFGefTO8epDNoNInlrjoAFK1TClcmw9lfHB47pjvU9l8tVe69/XAAyvqTOnLvre6XmB49IpRlUuMLtq+p9sflT1DNHNKcBN29rr3tXNuaNFobhGPqgPOEQiw2slvQsBANAPips0EAUsCo+aEnNmvDhGLlHAknqQfH82y+wnibf24s5fAQCAqpSNRiHJ8mtO0mG29/wAkCfpJ1l41ov//SW2AX+rLNOWjYNYscC0Ntr1fdur7Mch3ocXdc4FBXauskzbACnqQ0RR5+y+AKhIUwOkM24r6JreFwBVqRsD4jgTqmCfAFSl+gZJZ2zfAMijuPn5B5HB2coya115AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTExLTA3VDA5OjQxOjU4KzAwOjAw4GF3zgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMS0wN1QwOTo0MTo1OCswMDowMJE8z3IAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat left 15px center;
  background-size: 15px 15px;
  color: #4f4f4f;
  border: 1px solid #ededed;
  padding: 10px 15px 10px 40px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  opacity: 0.7;
}
.splitpayment .exfilter .csv,
.paymentmana .exfilter .csv,
.roles .exfilter .csv,
.instantpay .exfilter .csv,
.subscription .exfilter .csv,
.alerttemplate .exfilter .csv,
.merrole .exfilter .csv,
.merusermana .exfilter .csv,
.instalmentDetail .exfilter .csv {
  background: #fff;
  color: #009bdd;
  border-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  border: 1px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
}
.splitpayment .exfilter .add {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}
.Notification .add,
.userpayment .add {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  float: right;
}
.splitpaymenttable .ant-table-tbody > tr > td,
.roletable .ant-table-tbody > tr > td,
.instantpaytable .ant-table-tbody > tr > td,
.subscriptiontable .ant-table-tbody > tr > td,
.alerttable .ant-table-tbody > tr > td,
.Merchantdashtable .ant-table-tbody > tr > td,
.MerchantrHistorytable .ant-table-tbody > tr > td,
.merroletable .ant-table-tbody > tr > td,
.merusermanatable .ant-table-tbody > tr > td,
.notifitable .ant-table-tbody > tr > td,
.MerSaleRepTable .ant-table-tbody > tr > td,
.Userdashtable .ant-table-tbody > tr > td,
.userpaymenttable .ant-table-tbody > tr > td,
.instalmentDetailtable .ant-table-tbody > tr > td {
  font-size: 13px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 20px;
  text-transform: capitalize;
  opacity: 0.8;
  text-align: center;
}
.splitpaymenttable .ant-table-thead > tr > th,
.roletable .ant-table-thead > tr > th,
.instantpaytable .ant-table-thead > tr > th,
.subscriptiontable .ant-table-thead > tr > th,
.alerttable .ant-table-thead > tr > th,
.Merchantdashtable .ant-table-thead > tr > th,
.MerchantrHistorytable .ant-table-thead > tr > th,
.merroletable .ant-table-thead > tr > th,
.merusermanatable .ant-table-thead > tr > th,
.notifitable .ant-table-thead > tr > th,
.MerSaleRepTable .ant-table-thead > tr > th,
.Userdashtable .ant-table-thead > tr > th,
.userpaymenttable .ant-table-thead > tr > th,
.instalmentDetailtable .ant-table-thead > tr > th {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 15px 15px;
  text-align: center;
}
.splitpaymenttable .action .editdele,
.roletable .action .editdele,
.instantpaytable .action .editdele,
.subscriptiontable .action .editdele,
.alerttable .action .editdele,
.merroletable .action .editdele,
.merusermanatable .action .editdele,
.userpaymenttable .action .editdele {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymenttable .refund button,
.Merchantdashtable .refund button,
.MerchantrHistorytable .refund button {
  /* background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%); */
  background: #ffba5c;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  padding: 8px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}
.deletepopover .refundbt {
  background: #ffba5c;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 7px 20px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}
.paymenttable .refundtext,
.Merchantdashtable .refundtext,
.MerchantrHistorytable .refundtext,
.Userdashtable .refundtext,
.instalmentDetailtable .refundtext {
  color: #ffba5c;
}
.userpopover .subsc label:first-child {
  width: 105px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.userpopover .subsc label:last-child {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.subinfo p {
  width: 250px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.refundpopover .subsc label:first-child {
  width: 150px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.refundpopover .subsc label:last-child {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
#EditUserModal {
  margin: 100px auto 0;
  border-radius: 10px;
  width: 550px;
}
#EditUserSplitModal,
#addroleModal,
#addinstantuserModal,
#subscriptionmodal,
#alerttempmodal,
#contentmodal,
#meraddroleModal,
#meradduserModal,
#PlanPaymentModal,
#addcardModal {
  margin: 40px auto 0;
  border-radius: 10px;
  width: 550px;
  padding: 0;
}
#dashmodaluser {
  margin: auto;
  border-radius: 10px;
  width: 550px;
  padding: 0;
}
#addinstantuserModal .backtext,
#addroleModal .backtext,
#EditUserSplitModal .backtext,
#subscriptionmodal .backtext,
#alerttempmodal .backtext,
#contentmodal .backtext,
#ForgotPasswordOtp .backtext,
#meraddroleModal .backtext,
#meradduserModal .backtext,
#PlanPaymentModal .backtext,
#addcardModal .backtext,
#dashmodaluser .backtext {
  background: #009bdd;
  color: #fff;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#EditUserSplitModal label,
#addroleModal label,
#addinstantuserModal label,
#subscriptionmodal label,
#alerttempmodal label,
#contentmodal label,
#ForgotPasswordOtp label,
#meraddroleModal label,
#meradduserModal label,
#PlanPaymentModal label,
#addcardModal label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  opacity: 0.8;
}
#EditUserSplitModal input,
#addroleModal input,
#addinstantuserModal input,
#subscriptionmodal input,
#alerttempmodal input,
#ForgotPasswordOtp input,
#meraddroleModal input,
#meradduserModal input,
#addcardModal input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  color: #47525e;
}
#EditUserSplitModal .split input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 35%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-right: 10px;
  color: #47525e;
}
#EditUserSplitModal .split select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 60%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#EditUserSplitModal select,
#addroleModal select,
#subscriptionmodal select,
#alerttempmodal select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#addinstantuserModal select,
#meradduserModal select,
#addcardModal select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 40px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  color: #47525e;
}
#subscriptionmodal textarea {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 80px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#subscriptionmodal textarea:focus {
  outline: 0;
}
#EditUserSplitModal select:focus,
#addroleModal select:focus,
#addinstantuserModal select:focus,
#subscriptionmodal select:focus,
#alerttempmodal select:focus,
#meradduserModal select:focus #addcardModal select:focus {
  outline: 0;
}
#EditUserSplitModal input::-webkit-input-placeholder, #addroleModal input::-webkit-input-placeholder, #addinstantuserModal input::-webkit-input-placeholder, #subscriptionmodal input::-webkit-input-placeholder, #alerttempmodal input::-webkit-input-placeholder, #meraddroleModal input::-webkit-input-placeholder, #meradduserModal input::-webkit-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserSplitModal input:-ms-input-placeholder, #addroleModal input:-ms-input-placeholder, #addinstantuserModal input:-ms-input-placeholder, #subscriptionmodal input:-ms-input-placeholder, #alerttempmodal input:-ms-input-placeholder, #meraddroleModal input:-ms-input-placeholder, #meradduserModal input:-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserSplitModal input::-ms-input-placeholder, #addroleModal input::-ms-input-placeholder, #addinstantuserModal input::-ms-input-placeholder, #subscriptionmodal input::-ms-input-placeholder, #alerttempmodal input::-ms-input-placeholder, #meraddroleModal input::-ms-input-placeholder, #meradduserModal input::-ms-input-placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserSplitModal input::placeholder,
#addroleModal input::placeholder,
#addinstantuserModal input::placeholder,
#subscriptionmodal input::placeholder,
#alerttempmodal input::placeholder,
#meraddroleModal input::placeholder,
#meradduserModal input::placeholder {
  color: #47525e;
  opacity: 0.7;
}
#EditUserSplitModal .switch,
#addroleModal .switch,
#addinstantuserModal .switch,
#subscriptionmodal .switch,
#alerttempmodal .switch,
#meraddroleModal .switch,
#meradduserModal .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 5px 0 0 10px;
}
#EditUserSplitModal .switch input,
#addroleModal .switch input,
#addinstantuserModal .switch input,
#subscriptionmodal .switch input,
#alerttempmodal .switch input,
#meraddroleModal .switch input,
#meradduserModal .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
#EditUserSplitModal .slider,
#addroleModal .slider,
#addinstantuserModal .slider,
#subscriptionmodal .slider,
#alerttempmodal .slider,
#meraddroleModal .slider,
#meradduserModal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #797979;
  transition: 0.4s;
}
#EditUserSplitModal .slider:before,
#addroleModal .slider:before,
#addinstantuserModal .slider:before,
#subscriptionmodal .slider:before,
#alerttempmodal .slider:before,
#meraddroleModal .slider:before,
#meradduserModal .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
#EditUserSplitModal input:checked + .slider,
#addroleModal input:checked + .slider,
#addinstantuserModal input:checked + .slider,
#subscriptionmodal input:checked + .slider,
#alerttempmodal input:checked + .slider,
#meraddroleModal input:checked + .slider,
#meradduserModal input:checked + .slider {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
}
#EditUserSplitModal input:focus + .slider,
#addroleModal input:focus + .slider,
#addinstantuserModal input:focus + .slider,
#subscriptionmodal input:focus + .slider,
#alerttempmodal input:focus + .slider,
#meraddroleModal input:focus + .slider,
#meradduserModal input:focus + .slider {
  box-shadow: 0 0 1px #009bdd;
}
#EditUserSplitModal input:checked + .slider:before,
#addroleModal input:checked + .slider:before,
#addinstantuserModal input:checked + .slider:before,
#subscriptionmodal input:checked + .slider:before,
#alerttempmodal input:checked + .slider:before,
#meraddroleModal input:checked + .slider:before,
#meradduserModal input:checked + .slider:before {
  transform: translateX(23px);
}
#EditUserSplitModal .slider.round,
#addroleModal .slider.round,
#addinstantuserModal .slider.round,
#subscriptionmodal .slider.round,
#alerttempmodal .slider.round,
#meraddroleModal .slider.round,
#meradduserModal .slider.round {
  border-radius: 34px;
}
#EditUserSplitModal .slider.round:before,
#addroleModal .slider.round:before,
#addinstantuserModal .slider.round:before,
#subscriptionmodal .slider.round:before,
#alerttempmodal .slider.round:before,
#meraddroleModal .slider.round:before,
#meradduserModal .slider.round:before {
  border-radius: 50%;
}
#EditUserSplitModal .react-responsive-modal-closeButton,
#addroleModal .react-responsive-modal-closeButton,
#addinstantuserModal .react-responsive-modal-closeButton,
#subscriptionmodal .react-responsive-modal-closeButton,
#alerttempmodal .react-responsive-modal-closeButton,
#contentmodal .react-responsive-modal-closeButton,
#ForgotPasswordOtp .react-responsive-modal-closeButton,
#meraddroleModal .react-responsive-modal-closeButton,
#meradduserModal .react-responsive-modal-closeButton,
#PlanPaymentModal .react-responsive-modal-closeButton,
#addcardModal .react-responsive-modal-closeButton,
#dashmodaluser .react-responsive-modal-closeButton {
  display: none;
}
#EditUserSplitModal .eduser,
#addroleModal .eduser,
#addinstantuserModal .eduser,
#subscriptionmodal .eduser,
#alerttempmodal .eduser,
#contentmodal .eduser,
#meraddroleModal .eduser,
#meradduserModal .eduser,
#PlanPaymentModal .eduser,
#ForgotPasswordOtp .eduser,
#addcardModal .eduser,
#dashmodaluser .eduser {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
}
#EditUserSplitModal .edituser,
#addroleModal .edituser,
#addinstantuserModal .edituser,
#subscriptionmodal .edituser,
#alerttempmodal .edituser,
#contentmodal .edituser,
#ForgotPasswordOtp .edituser,
#meraddroleModal .edituser,
#meradduserModal .edituser,
#PlanPaymentModal .edituser,
#addcardModal .edituser,
#dashmodaluser .edituser {
  padding: 20px;
}
#EditUserSplitModal .closeicon,
#addroleModal .closeicon,
#addinstantuserModal .closeicon,
#subscriptionmodal .closeicon,
#alerttempmodal .closeicon,
#contentmodal .closeicon,
#ForgotPasswordOtp .closeicon,
#meraddroleModal .closeicon,
#meradduserModal .closeicon,
#PlanPaymentModal .closeicon,
#addcardModal .closeicon,
#dashmodaluser .closeicon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
}
#EditUserSplitModal .Editbtn,
#addroleModal .Editbtn,
#addinstantuserModal .Editbtn,
#subscriptionmodal .Editbtn,
#alerttempmodal .Editbtn,
#contentmodal .Editbtn,
#ForgotPasswordOtp .Editbtn,
#meraddroleModal .Editbtn,
#meradduserModal .Editbtn,
#PlanPaymentModal .Editbtn,
#addcardModal .Editbtn,
#dashmodaluser .Editbtn {
  text-align: center;
}
#EditUserSplitModal .Editbtn button,
#addroleModal .Editbtn button,
#addinstantuserModal .Editbtn button,
#subscriptionmodal .Editbtn button,
#alerttempmodal .Editbtn button,
#contentmodal .Editbtn button,
#ForgotPasswordOtp .Editbtn button,
#meraddroleModal .Editbtn button,
#meradduserModal .Editbtn button,
#PlanPaymentModal .Editbtn button,
#addcardModal .Editbtn button,
#dashmodaluser .Editbtn button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  padding: 10px 30px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  border: 0;
  width: 100px;
  height: 40px;
  margin: 15px 0 0 0;
}
#ForgotPasswordOtp .Editbtn button {
  min-width: 100px;
  width: auto;
}
#ForgotPasswordOtp .Editbtn button {
  padding: 10px 15px;
}
.subscriptiontable .free {
  background-image: linear-gradient(to right, #ffb52f, #fff, #ffb52f);
  padding: 5px 20px;
  border-radius: 15px;
  color: #000;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #ffb52f;
}
.subscriptiontable .oneclick {
  background-image: linear-gradient(to right, #489af7, #fff, #489af7);
  padding: 5px 20px;
  border-radius: 15px;
  color: #000;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #489af7;
}
.subscriptiontable .splitpay {
  background-image: linear-gradient(to right, #2f907d, #fff, #2f907d);
  padding: 5px 20px;
  border-radius: 15px;
  color: #000;
  line-height: 15px;
  font-size: 12px;
  border: 1px solid #2f907d;
}
.subscriptiontable .combo {
  background-image: linear-gradient(to right, #ff8484, #fff, #ff8484);
  padding: 5px 20px;
  border-radius: 15px;
  color: #000;
  line-height: 15px;
  font-size: 12px;
  border: 1px solid #ff8484;
}
.alerttable .CommNoti img {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
#alerttempmodal input[type="checkbox"] {
  display: block;
  border: 1px solid #ededed;
  color: #47525e;
  width: auto;
  height: auto;
}
#alerttempmodal .emailcheck {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}
#alerttempmodal .emailcheck label {
  display: inline-block;
  cursor: pointer;
}
#alerttempmodal .emailcheck .em {
  margin-right: 40px;
  margin-bottom: 10px;
}
#alerttempmodal .commode {
  font-weight: 600;
}
.secondtab ul {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.secondtab .nav-tabs .nav-item.show .nav-link,
.secondtab .nav-tabs .nav-link.active {
  background: #009bdd;
  color: #fff;
  font-size: 14px;
  border: 1px solid #009bdd;
  border-radius: 0;
}
.secondtab .nav-tabs .nav-link {
  font-size: 14px;
  color: #4a4a4a;
  border: 1px solid #ededed;
  border-radius: 0;
}
#contentmodal .emailarea {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 80px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #47525e;
}
#contentmodal .emailarea:focus {
  outline: 0;
}
#contentmodal .editorte {
  padding: 20px 0 5px 5px;
}
.emailimg {
  width: 18px;
  height: 18px;
}
/* Split payment Management CSS End Kalpesh */
#addroleModal .edituser .ant-select-selector,
#meraddroleModal .edituser .ant-select-selector {
  border-radius: 5px;
  border: 1px solid #ededed;
  /* padding: 10px; */
  padding: 5px 10px;
  width: 100%;
  font-size: 14px;
  /* height: 40px; */
  font-weight: 400;
  /* margin-bottom: 20px; */
  color: #47525e;
}
#addroleModal .edituser .ant-select-selector input,
#meraddroleModal .edituser .ant-select-selector input {
  padding: 0;
  margin: 0;
  border: 0;
  height: auto;
}
.Error {
  font-size: 12px;
  color: red;
  display: block;
  /* display: none; */
  text-align: left;
}
.mersign .input-cntr {
  margin-bottom: 0;
}
.mersign {
  margin-bottom: 20px;
}
.marginbot {
  margin-bottom: 15px;
}
/* Split payment Management CSS End Kalpesh */

/* Merchant Marketing */
.merchantMar {
  position: relative;
}
header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  padding: 15px;
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #fff;
  z-index: 1;
}
header .inpay a h4 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #fff;
}
header .foryou ul li {
  display: inline;
  margin: 0 30px;
}
header .foryou ul li a {
  font-size: 14px;
  color: #fff;
}
header .signbtn .sigin {
  background: transparent;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #fff;
  margin: 0 0 0 15px;
  padding: 5px 20px;
  line-height: 20px;
}
header .signbtn .sigup {
  background: #fff;
  color: #0082c7;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #fff;
  margin: 0 0 0 15px;
  padding: 5px 20px;
  line-height: 20px;
}
/* .merchantMar .carousel {
  z-index: -1;
  height: 100vh;
} */
.carousel img {
  max-height: 95vh;
  object-fit: cover;
}
.merchantMar .carousel-inner {
  height: 100vh;
  width: 100%;
}
.merchantMar .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #fff !important;
  opacity: 1 !important;
  background: transparent !important;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #fff !important;
}
.carousel .control-dots .dot:focus {
  outline: 0;
}
.carousel-caption {
  position: absolute;
  top: 55%;
  left: 10%;
  color: #fff;
  transform: translate(-10%, -55%);
  width: 400px;
  text-align: left;
}
.carousel-caption h4 {
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  text-transform: uppercase;
}
.carousel-caption p {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  padding: 25px 0;
}
.carousel-caption button {
  border-radius: 6px;
  background: #fff;
  color: #0082c7;
  border: 0;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;
}
.topbottom {
  padding-top: 60px;
  padding-bottom: 90px;
}
.topbottom h3 {
  font-size: 30px;
  font-weight: 600;
  padding: 0 0 40px 0;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #4a4a4a;
}
.safarimg img {
  width: 60px;
  height: 60px;
}
.safarimg {
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 50%;
  line-height: 120px;
  margin: auto;
  box-shadow: 0px 0px 10px #ccc;
}
.Safar h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 25px 0 0 0;
  line-height: 25px;
  opacity: 0.7;
}
.Safar p {
  font-size: 16px;
  font-weight: 400;
  padding: 7px 0 0 0;
  line-height: 20px;
  color: #4a4a4a;
  letter-spacing: 0px;
}
.Safar button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border: 0;
  text-align: center;
  padding: 10px;
  text-transform: capitalize;
  height: 30px;
  line-height: 10px;
  border-radius: 5px;
  margin-top: 15px;
}
.topbotto {
  padding-top: 60px;
  padding-bottom: 90px;
  background: #008ed2;
}
.topbotto h3 {
  font-size: 30px;
  font-weight: 600;
  padding: 0 0 40px 0;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
.Safarnew {
  text-align: center;
  background: #70c1e6;
  border-radius: 6px;
  margin-bottom: 20px;
}
.Safarnew h3 {
  font-size: 25px;
  font-weight: 600;
  padding: 35px 0;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}
.foot {
  background: #ededed;
}
.foot footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15px;
  color: #fff;
  width: 100%;
}
.foot footer .inpay h4 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #003280;
  margin-bottom: 10px;
}
.foot footer .inpay span {
  font-size: 11px;
  color: #4a4a4a;
  margin-right: 5px;
}
.foot footer .inpay a {
  color: #0082c7 !important;
  font-size: 11px;
  text-decoration: underline !important;
  margin: 0;
  padding: 0;
}
.foot .foryou ul li:nth-child(1),
.foot .foryou ul li:nth-child(2) {
  margin-right: 40px;
}
.foot .foryou ul {
  display: flex;
}
.foot .foryou ul li p {
  display: block;
  font-size: 14px;
  color: #4a4a4a;
  line-height: 25px;
  cursor: pointer;
}
.foot .foryou ul li p:hover {
  color: #008ed2;
}
.foot .signbtn ul {
  display: flex;
}
.foot .signbtn ul li {
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #ededed;
  border: 1px solid #008ed2;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #008ed2;
}
.foot .signbtn ul li:hover,
.foot .signbtn ul li:focus {
  background: #008ed2;
  color: #ededed;
}
.foot .signbtn ul li:last-child {
  margin: 0;
}
/* Contact */
.marketingContact header,
.marketingApi header,
.marketingSignHome header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: linear-gradient(to right, #009bdd 0%, #003280 100%);
  padding: 15px;
  color: #fff;
  width: 100%;
  border-bottom: 1px solid #fff;
  position: unset;
}
.marketingContact .topbottom,
.marketingApi .topbottom,
.marketingSignHome .topbottom {
  padding: 40px 0 50px 0;
}
.marketingContact .topbottom h3,
.marketingApi .topbottom h3 {
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 10px 0;
  line-height: 35px;
  text-align: left;
  text-transform: capitalize;
  color: #4a4a4a;
}
.marketingContact .card {
  width: 100%;
  flex-direction: row;
  border: 0;
}
.marketingContact .card-a {
  width: 70%;
  background: #fff;
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 20px 25px;
}
.marketingContact .card-b {
  width: 30%;
  background: #009bdd;
  border-top: 1px solid #009bdd;
  border-right: 1px solid #009bdd;
  border-bottom: 1px solid #009bdd;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 45px 25px;
}
.marketingContact .card-a label {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  padding-bottom: 5px;
  padding-left: 10px;
  text-transform: capitalize;
}
.marketingContact .card-a input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 45px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  color: #47525e;
}
.marketingContact .card-a input:focus {
  outline: 0;
}
.marketingContact .card-a textarea {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 80px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  color: #47525e;
}
.marketingContact .card-a textarea:focus {
  outline: 0;
}
.marketingContact .card-a button {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border-radius: 6px;
  text-align: center;
  margin: 0;
  padding: 10px 35px;
  text-transform: capitalize;
  line-height: 20px;
  cursor: pointer;
}
.marketingContact .card-b ul li {
  margin-bottom: 30px;
}
.marketingContact .card-b label {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  display: flex;
}
.marketingContact .card-b label i {
  font-size: 18px;
  margin-right: 15px;
}
.apitab .ant-tabs-nav {
  margin: 0;
}
.apitab .ant-tabs-top > .ant-tabs-nav::before,
.apitab .ant-tabs-top > div > .ant-tabs-nav::before,
.apitab .ant-tabs-bottom > .ant-tabs-nav::before,
.apitab .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: 0;
}
.apitab .ant-tabs-tab-btn {
  background: #f4f6fd;
  color: #4a4a4a;
  font-size: 16px;
  padding: 10px 15px;
  margin-right: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.apitab .ant-tabs-tab {
  margin: 0;
  padding: 0;
}
.apitab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  background: #009bdd;
  font-size: 16px;
  padding: 10px 15px;
}
.apitab .ant-tabs-ink-bar {
  background: transparent;
}
.apitab .ant-tabs-content-holder {
  border: 1px solid #ededed;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 30px 50px;
}
.apitab .ant-tabs-content-holder p {
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
  letter-spacing: px;
  line-height: 20px;
}
.apitab .ant-tabs-content-holder ul {
  margin-top: 25px;
  list-style: disc;
  font-size: 20px;
}
.apitab .ant-tabs-content-holder ul li {
  margin-bottom: 15px;
}
.marketingSignHome .mainCard {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.marketingSignHome .mainCard .card {
  width: 300px;
  text-align: center;
  background-image: linear-gradient(0deg, #009bdd 0%, #003280 100%);
  margin: 0 20px;
  padding: 0 20px;
  border: 0;
  border-radius: 10px;
}
.marketingSignHome .mainCard .card h4 {
  font-size: 20px;
  font-weight: 500;
  padding: 15px 0 15px 0;
  line-height: 35px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
}
.marketingSignHome .mainCard .card p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  letter-spacing: px;
  line-height: 20px;
}
.marketingSignHome .mainCard .card .signup button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 25px;
}
.marketingSignHome .mainCard .card .signup1 button {
  background: #fff;
  border: 1px solid #fff;
  color: #003280;
  border-radius: 6px;
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
  margin-bottom: 25px;
}
.marketingSignHome .mainCard .card .Circleimg {
  background: #fff;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  margin: -40px auto 0;
  border: 1px solid #003280;
}
.marketingSignHome .mainCard .card .Circleimg img {
  width: 40px;
  height: 40px;
}
.marketingSignHome .mainCard .card .Video img {
  width: 190px;
  height: 95px;
  border-radius: 6px;
}
.marketingSignHome .mainCard .card .Video {
  margin: 20px 0px 40px 0;
}
.already {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 30px 0 0 0;
}
.already a {
  color: #003280;
  cursor: pointer;
  padding-left: 3px;
  text-decoration: underline;
}
.signup .margin {
  margin-bottom: 0;
}
.signup .Verify {
  font-size: 14px;
  color: #003280;
  font-weight: 500;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
  padding-top: 5px;
  text-decoration: underline;
}

/* Merchant Dashboard */
.Merchantdashboard .image,
.Userdashboard .image {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
}
.Merchantdashboard .card,
.Userdashboard .card {
  border: 0;
  border-radius: 10px;
  padding: 20px;
  display: inline-block;
  /* flex-direction: row; */
  width: 100%;
  margin-bottom: 10px;
}
.Merchantdashboard .text,
.Userdashboard .text {
  padding: 0 0 0 15px;
  width: calc(100% - 80px);
  margin: 0;
  float: right;
}
.Merchantdashboard .text label,
.Userdashboard .text label {
  display: block;
}
.Merchantdashboard .text label:nth-child(1),
.Userdashboard .text label:nth-child(1) {
  color: #009bdd;
  font-size: 24px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
}
.Merchantdashboard .text label:nth-child(2),
.Userdashboard .text label:nth-child(2) {
  color: #47525e;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  font-weight: 500;
}
.merrefunfpopover .subsc label:first-child {
  /* width: 70px; */
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.merrefunfpopover .subsc label:last-child {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.userDashpopover .subsc label:first-child {
  width: 110px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
.userDashpopover .subsc label:last-child {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  line-height: 30px;
  text-transform: capitalize;
}
/* Merchant Subscription Start Kalpesh */
.merSubsc .card {
  border: 1px solid #ededed;
  border-radius: 20px;
  text-align: center;
  padding: 15px 20px;
}
.merPlanPayment .cardp {
  border: 1px solid #ededed !important;
  border-radius: 20px !important;
  text-align: center !important;
  padding: 15px 20px !important;
}
.merPlanPayment .card {
  border: 1px solid #ededed;
  border-radius: 20px;
  padding: 30px 40px 45px 40px;
}
.merSubsc .card h3,
.merPlanPayment .card h3 {
  font-size: 20px;
  font-weight: bold;
  color: #009bdd;
  text-transform: capitalize;
  display: block;
  line-height: 20px;
  padding: 10px 0 10px 0;
  min-height: 70px;
}
.merSubsc .card h3 span,
.merPlanPayment .card h3 span {
  display: block;
  font-size: 12px;
}
.merSubsc .card p,
.merPlanPayment .card p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  display: block;
  line-height: 18px;
}
.merSubsc .card h4,
.merPlanPayment .card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  display: block;
  line-height: 25px;
  padding: 10px 0 15px 0;
}
.merSubsc .card .start,
.merPlanPayment .card .start {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border: 0;
  text-align: center;
  margin: auto;
  padding: 8px 25px;
  text-transform: capitalize;
  border-radius: 5px;
  cursor: pointer;
  line-height: 18px;
}
.merSubsc .cardtext,
.merPlanPayment .cardtext {
  margin: 25px 0 10px 0;
}
.merSubsc .cardtext img,
.merPlanPayment .cardtext img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.merSubsc .cardtext label,
.merPlanPayment .cardtext label {
  font-size: 11px;
  font-weight: 400;
  color: #000;
  display: flex;
  line-height: 25px;
  justify-content: center;
  align-items: center;
}
.merSubsc .card select,
.merPlanPayment .card select {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 50px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #47525e;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAElBMVEUAAAAAmt0Am90Am90Am90AAACj3q0hAAAABHRSTlMAd3+ApzL+VgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCxMKJhSg7ujbAAAAUklEQVQoz83RywnAMAwDUImu0AE6QmYQ3X+mBhOCpQVSHc3zBwz8JPdrEeiFJ4hmDwMYUY1lgEa0NjPAJtrHMcAiavczQJEOihiYxAFwjdN/iHyP3SGL8yzTqwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMS0xOVQxMDozODoyMCswMDowMLHb2I8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTEtMTlUMTA6Mzg6MjArMDA6MDDAhmAzAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==) no-repeat right 15px
    center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  line-height: 20px;
  background-size: 12px 12px;
  margin: 10px 0 30px 0;
}
.merSubsc .card select:focus,
.merPlanPayment .card select:focus {
  outline: 0;
}
.merSubsc .card.active {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.merSubsc .card.active .start {
  background-image: linear-gradient(0deg, #00cb18 0%, #00a213 100%);
}
.merPlanPayment .planinput label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  opacity: 0.8;
  text-align: left;
  padding-left: 10px;
}
.merPlanPayment .planinput input {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 45px;
  font-weight: 400;
  margin: 0;
  color: #47525e;
}
.merPlanPayment .paybtn {
  margin: 20px 0 0 0;
}
.merPlanPayment .paybtn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  text-transform: uppercase;
  display: block;
  line-height: 20px;
  padding: 22px 0;
  min-height: 0;
}
.merPlanPayment .paybtn .start {
  background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border: 0;
  text-align: center;
  margin: auto;
  padding: 8px 25px;
  text-transform: capitalize;
  border-radius: 5px;
  cursor: pointer;
  line-height: 18px;
}
.cardp .month {
  border-radius: 5px;
  border: 1px solid #ededed;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  height: 50px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #4a4a4a;
  line-height: 30px;
  background-size: 12px 12px;
  margin: 10px 0 30px 0;
}
#PlanPaymentModal p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 0;
  line-height: 20px;
}
#dashmodaluser p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  line-height: 20px;
}
.userpaymenttable .radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 0;
}
.userpaymenttable .radio-item input[type="radio"] {
  display: none;
}
.userpaymenttable .radio-item label {
  cursor: pointer;
}
.userpaymenttable .radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #009bdd;
  background-color: transparent;
}
.userpaymenttable .radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #009bdd;
}
.adcontent {
  width: auto !important;
}
/* Admin Contant Management Page Css*/
.contanentmanagement {
  background: #fff;
  padding: 30px 35px;
  margin-top: 10px;
}
.contanentmanagement .Usermana {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  padding: 0 0 20px 0;
  color: #009bdd;
  text-transform: capitalize;
  /* / display: inline; / */
}
.contanentmanagement .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #009bdd;
  color: rgb(255 255 255 / 85%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 16px;
}
.contanentmanagement .ant-collapse > .ant-collapse-item {
  margin-bottom: 35px;
}
.contanentmanagement .ant-collapse {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.contanentmanagement .ant-collapse-content > .ant-collapse-content-box {
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 3%;
  padding-bottom: 3%;
}
.contanentmanagement .fsizectmm {
  font-size: 18px;
}
.contanentmanagement .dflex {
  display: flex;
  margin-bottom: 10px;
}
.contanentmanagement .tarea {
  width: 90%;
  margin-left: 10px;
}
.contanentmanagement .editimg {
  width: 20px;
  height: 20px;
  margin-top: 13px;
}
.contanentmanagement .editimgsize {
  width: 20px;
  height: 20px;
  margin-left: 30px;
}
.contanentmanagement .deleteimgsize {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.contanentmanagement .dhide {
  visibility: hidden;
}
.contanentmanagement .que,
.contanentmanagement .ans {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500px;
  margin-right: 15px;
  padding-top: 8px;
  width: 10px;
}
.contanentmanagement .nosize {
  font-size: 16px;
  font-weight: 400;
  color: blue;
  width: 10px;
  padding-top: 10px;
}
.ant-collapse-content-box {
  padding-bottom: 40px;
  padding-top: 40px;
}
.contanentmanagement
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  display: none;
}
.contanentmanagement .updnimg {
  float: right;
  height: 20px;
  width: 20px;
}
.contanentmanagement .iconsizectmm {
  width: 20px;
  height: 20px;
}
/* End Admin Contant Management Page Css*/
.icon-eye-slash {
  color: gray;
  font-size: 19px;
}
.adcontent {
  width: auto !important;
}
.sidebarresp .menubtn {
  display: none;
}
.sidebarresp {
  display: none;
}
.filt {
  display: none;
}
.footerlink li:hover a {
  color: white;
}
.select-country{
  width: 364px;
}
.anticon {
  vertical-align: text-top;
}
.react-tel-input .country-list {
  width: 418px !important; 
  text-align: left !important;
}

.react-tel-input .form-control{
  width: 100% !important;
}
@media screen and (min-width: 992px) and (max-width: 1240px) {
    .merSubsc .card {
        padding: 15px 20px;
    }
    .merSubsc .cardtext label, 
    .merPlanPayment .cardtext label {
        font-size: 12px;
        display: flex;
        line-height: 15px;
        justify-content: center;
        text-align: left;
        align-items: end;
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
    .Safar {
        margin-bottom: 20px;
    }
    .foot .foryou ul li:nth-child(1), .foot .foryou ul li:nth-child(2) {
        margin-right: 15px;
    }
    .foot .foryou ul li p {
        font-size: 12px;
    }
    .foot footer .inpay h4 {
        margin: 0;
    }
    .marketingContact .topbottom, 
    .marketingApi .topbottom, 
    .marketingSignHome .topbottom {
        padding: 40px;
    }
    .apitab .ant-tabs-content-holder {
        padding: 0 40px;
    }
    .merSubsc .card {
        margin-bottom: 20px;
        padding: 15px 20px;
    }
    .merPlanPayment .cardtext label {
        font-size: 12px;
    }
    .merSubsc .card p, 
    .merPlanPayment .card p {
        font-size: 14px;
    }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .Safar  {
        margin-bottom: 20px;
    }
    .foot footer {
        display: block;
    }
    .foot .foryou ul {
        display: flex;
        justify-content: space-between;
    } 
    .foot .foryou,
    .foot .inpay {
        margin: 0 0 30px 0;
    }
    .foot .foryou ul li:nth-child(1), .foot .foryou ul li:nth-child(2) {
        margin-right: 20px;
    }
    .foot .foryou ul li p {
        font-size: 13px;
    }
    .topbotto,
    .topbottom {
        padding-top: 40px;
        padding-bottom: 40px;
    } 
    header .foryou {
        display: none;
    }
    header,
    .marketingContact header,
    .marketingApi header,
    .marketingSignHome header {
        justify-content: space-between;
    }
    .carousel-caption {
        text-align: center;
        width: 100%;
    }
    .carousel .slide img {
        height: 100vh;;
    }
    .carousel-caption {
        padding: 20px 10px;
    }
    .marketingContact .card {
        padding: 0 20px;
        flex-direction: column;
    }
    .marketingContact .topbottom h3,
    .marketingApi .topbottom h3 {
        padding: 0 0 10px 20px; 
    }
    .marketingContact .card-a,
    .marketingContact .card-b,
    .merPlanPayment .card {
        width: 100%;
        margin-bottom: 20px;
    }
    .marketingContact .card-a {
        border-right: 1px solid #EDEDED;
        border-radius: 30px;
    }
    .marketingContact .card-b {
        border-left: 1px solid #009bdd;
        border-radius: 30px;
    }
    .marketingContact .card-b ul li:last-child {
        margin-bottom: 0;
    }
    .apitab {
        padding: 0 20px;
    }
    .apitab .ant-tabs-content-holder {
        padding: 0 25px;
    }
    .marketingSignHome .mainCard .card {
        margin: 0 auto;
        width: 280px;
    }
    .ant-table-content {
        overflow: auto;
    }
    .exfilter a:first-child,
    .exfilter label:first-child {
        padding: 10px 10px;
        margin-left: 0;
        font-size: 12px;
    }
    .exfilter label:last-child {
        padding: 10px 10px;
        margin-left: 8px;
        font-size: 12px;
    }
    .merSubsc .card {
        margin-bottom: 20px;
        padding: 20px;
    }
    .merSubsc .cardtext label, 
    .merPlanPayment .cardtext label {
        line-height: 17px;
        justify-content: center;
        align-items: unset;
        margin-bottom: 10px;
        text-align: left;
    }
    .merPlanPayment .card {
        padding: 30px;
    }
    .merPlanPayment .paybtn .start {
        padding: 8px 20px;
    }
    /* Merchantusermanagement Responsive changes by sahaj */
    .mladdusr {
        margin-left: 20px;
    }
    /* Merchant Profile Page By sahaj */
    .mainprofile h3 {
        font-size: 17px;
    }
    /* Changes by sahaj */
    .userpayment .Usermana,
    .merrole .Usermana,
    .merusermana .Usermana,
    .Notification .Usermana,
    .UserTrans .Usermana,
    .alerttemplate .Usermana,
    .merchManagement .Usermana,
    .userManagement .Usermana,
    .paymentmana .Usermana,
    .roles .Usermana,
    .splitpayment .Usermana,
    .subscription .Usermana,
    .merSubsc .Usermana,
    .MerSaleReport .Usermana,
    .instantpay .Usermana,
    .Userdashboard .dash,
    .dashboard .dash,
    .Merchantdashboard .dash,
    .adminsetting .Usermana,
    .MerTraHistory .Usermana
    .splitpayment .Usermana,
    .MerTraHistory .Usermana,
    .merPlanPayment .Usermana{
        font-size: 17px;
        text-align: left;
    }
    .userManagement,
    .merchManagement, 
    .usermanadetail, 
    .mermanadetail, 
    .adminsetting, 
    .MerTraHistory, 
    .MerSaleReport, 
    .merSubsc, 
    .merPlanPayment, 
    .UserTrans {
        padding: 30px 20px;
        margin-top: 60px;
    }
    .merrole .exfilter .csv, 
    .merusermana .exfilter .csv,
    .alerttemplate .exfilter .csv,
    .paymentmana .exfilter .csv,
    .roles .exfilter .csv,
    .splitpayment .exfilter .csv{
        padding: 10px 10px 10px 10px;
    }
    .merusermana .exfilter input,
    .alerttemplate .exfilter input,
    .roles .exfilter input,
    .splitpayment .exfilter input,
    .subscription .exfilter input,
    .merrole .exfilter input,
    .instantpay .exfilter input{
        margin-bottom: 13px;
    }
    .splitpayment, 
    .paymentmana, 
    .roles, 
    .instantpay, 
    .subscription, 
    .alerttemplate, 
    .merrole, 
    .merusermana {
        padding: 30px 25px;
        margin-top: 60px;
    }
    .splitpayment .exfilter .add,
    .Notification .add{
        padding: 10px 10px;
        margin-left: 14px;
        font-size: 12px;
    }
    #EditUserSplitModal .eduser,
    #subscriptionmodal .eduser,
    #addroleModal .eduser,
    #addinstantuserModal .eduser,
    #alerttempmodal .eduser,
    #meradduserModal .eduser,
    #meraddroleModal .eduser,
    #addcardModal .eduser,
    #PlanPaymentModal .eduser {
        font-size: 17px;
        text-align: left;
    }
    
    #EditUserSplitModal .closeicon,
    #subscriptionmodal .closeicon,
    #addroleModal .closeicon,
    #addinstantuserModal .closeicon,
    #alerttempmodal .closeicon,
    #meradduserModal .closeicon,
    #addcardModal .closeicon {
        width: 12px;
        height: 12px;
    }
    .dashboard .dash {
        font-size: 17px;
    }
    .splitpayment .exfilter,
    .paymentmana .exfilter,
    .roles .exfilter,
    .instantpay .exfilter,
    .subscription .exfilter,
    .alerttemplate .exfilter,
    .merrole .exfilter,
    .merusermana .exfilter,
    .userManagement .exfilter,
    .merchManagement .exfilter,
    .MerTraHistory .exfilter,
    .MerSaleReport .exfilter,
    .UserTrans .exfilter,
    .instalmentDetail .exfilter {
        float: none;
        margin: 15px 0 20px 0;
    }
    /* Css Mobile Toggle Sahaj */
    .header-left{
        display: none;
    }
    .header-right,
    .header-logo {
        display: none;
    }
    .sidebarresp{
        display: block;
    }
    .sidebarresp .menubtn{
        width: 20px;
        margin-right: 20px;
        display: block;
    }
    .respbarli{
        padding: 15px 10px;
        padding-left: 24px;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid #EDEDED;
    }
    .respnavb img,
    .logouticon {
        width: 15px;
        height: 15px;
    }
    .ant-drawer-body {
        padding: 0px;
        position: relative;
    }
    .repnoticount{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ff7240;
        font-size: 10px;
        color: #ffffff;
        font-weight: 700;
        text-align: center;
        position: absolute;
        bottom: 8px;
        left: 9px;
        line-height: 22px;
    }
    .ant-drawer-header{
        font-size: 26px;
        letter-spacing: 1px;
        color: #fefefe;
        font-weight: 700;
        background-color: #009bdd;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }
    .ant-drawer-title{
        font-size: 20px;
        color: #fefefe;
        font-weight: 700;
        text-align: center;
    }
    .sidebarresp {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        box-shadow: 1.338px 1.486px 5px rgba(0, 0, 0, 0.1);
        position: fixed;
        width: 100%;
        z-index: 999;
        background: #fff;
    }
    .header {
        display: unset;
        
    }
    .header-icons {
        margin-right: 15px;
    }
    .notification-count {
        bottom: 50%;
    }
    .profilerep {
        width: 25px !important;
    }
    .respnavb li:hover {
        background-color: #009bdd;
        color: #fff;
    }
    .sidbarspan {
        color: #4a4a4a;
    }
    .logoutbg {
        background-color: #009bdd;
        color: #fff !important;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .logoutbg .sidbarspan {
        color: #fff;
        font-size: 14px;
    }
    .mainprofile {
        margin-top: 17%;
    }
    .Merchantdashboard {
        margin: 60px 0 0 0;
    }
    .dashboard,
    .Merchantdashboard,
    .Notification,
    .Userdashboard,
    .userpayment {
        margin-top: 60px;
    }
    .filt {
        position: fixed;
        bottom: 14px;
        right: 15px;
        border-radius: 50% !important;
        display: block;
        background: linear-gradient(0deg, #003280 0%, #009bdd 100%);
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        z-index: 1;
    }
    .filt img {
        width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
        margin: 0 !important;
    }
    .filte {
        display: none;
    }
        /* Expand Table CSS By Kalpesh */
    .expandemail {
        font-size: 13px;
        font-weight: 500;
        color: #4f4f4f;
        line-height: 20px;
        text-transform: capitalize;
        opacity: 0.8;
    }
    .expandemailtext {
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
        line-height: 20px;
        text-transform: capitalize;
        opacity: 1;
    }
    .mobilevi {
        display: unset;
    }
    .drarfilter .back {
        background-color: rgb(245, 248, 249);
        border-radius: 5px;
        padding: 25px 0 20px 0;
    }
    .drarfilter .back input {
        border-radius: 5px;
        border: 1px solid #ededed;
        padding: 10px;
        width: 100%;
        font-size: 14px;
        height: 45px;
        font-weight: 400;
        margin-bottom: 15px;
        color: #47525e;
    }
    .drarfilter .back .calendar {
        border-radius: 5px;
        border: 1px solid #ededed;
        padding: 10px;
        width: 100%;
        font-size: 14px;
        height: 45px;
        font-weight: 400;
        margin-bottom: 10px;
        color: #47525e;
        background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCwYNCSmIEi2PAAABWUlEQVRIx+2TvUoDURSEv7O75AEUNFoEWRAEKwuNlailEG8KU1mkUvAJtNKUPoOtiMU20RRqY9TGBAQtrQI2sUtlkQ0sxyIxQoLujzaCUx3YmTlz596FPkzVqKkSgkGWFSYIgwOQW7XmQTOCZszu94IPlmjwULkGKaQ6J2wk2y7e2KY9vc9O4vyzb4GYV9LUU2tey1RZ5uZsJazELqsw0rlggabFOHDlteIu91pcAhMOAqIAcqp1aYSeu88SVRDn81P5KMrmQZYYBW2Ebx6GuuL23oG4uPENBOgZ/DiBdVwuxTfIl/Tgt/4FgNyMvQfBYeU56tTV9RPYaS1q0U5HnwYSBG3rBYJ29KkHo0bzCSqEfMmo0f8S/2SJgwaxS/wyQczqhg0qNaaiTMNH8EFHEvU3CvgOdZbYNkozpnxSt4B7WZ+TGqlkV0hHstb5oy5yhx9b7OutZMtP72qJEatTItOKAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTExLTA2VDEzOjA5OjQxKzAwOjAwE2UXNAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMS0wNlQxMzowOTo0MSswMDowMGI4r4gAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) no-repeat right 15px center;
        line-height: 14px;
        background-size: 15px 15px;
    }
    .drarfilter .back select {
        border-radius: 5px;
        border: 1px solid #ededed;
        padding: 10px;
        width: 100%;
        font-size: 14px;
        height: 45px;
        font-weight: 400;
        margin-bottom: 10px;
        color: #47525e;
        background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASxJREFUeNq8lUFqwkAUQEdxVbpzW7B05aqXKd6goTsX7V66FIorVyUb7+BGD1APUIQKSlGkF5BSMkRM38CfkIZmGEM08DJhMvNf8v8nqSVJos5xNIIguGJsm+sTOfawMME13MEtXFYs+YZ36FnRB7SgCRcVSX7gS2LrOqcdjGACS4grkMQSy8QchWG4s3Uxsld4gxe4gXpJyQE28AxzJCZjf4KZiTncw1o2lJGsJUYqUf88tZa8Psh4OFKS7s1KVEF6tDxVV8bYsybpnrxEOepg09iHT4gckkjW9PPp8hFZ2QzGsCqQRXLPrJkVSZRHZ2Vbf5NLYyxzaQs7P0Ee+betb2rwCNcyv4UhTF1vcozIpnEqXTWQuSdXTcqKsg3Sybyp9t1cO9dv4leAAQCnZXX+jQEPIwAAAABJRU5ErkJggg==) no-repeat right 15px center;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        line-height: 20px;
        background-size: 12px 12px;
    }
    .drarfilter .back select:focus {
        outline: 0;
    }
    .drarfilter .back .search {
        text-align: center;
    }
    .drarfilter .back .search button {
        background-image: linear-gradient(0deg, #003280 0%, #009bdd 100%);
        color: #fff;
        padding: 10px 25px;
        border-radius: 6px;
        margin-left: 10px;
        font-size: 15px;
        line-height: 20px;
        text-transform: capitalize;
        cursor: pointer;
        border: 0;
        margin: 20px 0 0 0;
    }
    .drarfilter .calendar input {
        margin: 0;
        border: 0;
    }
    .drarfilter .calendar .ant-picker-suffix {
        display: none;
    }
       /* Filter CSS By Kalpesh */
    .expandemail {
        font-size: 13px;
        font-weight: 500;
        color: #4f4f4f;
        line-height: 20px;
        text-transform: capitalize;
        opacity: 0.8;
    }
    .expandemailtext {
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
        line-height: 20px;
        text-transform: capitalize;
        opacity: 1;
    }
    .mobilevi {
        display: flex;
    }
    .mob-none {
        display: none;
    }
    .expandown,
    .expandown1 {
        width: 25px;
        height: 25px;
        background: linear-gradient(0deg, #003280 0%, #009bdd 100%);
        border-radius: 50%;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
    }
    .expandown img {
        width: 13px;
        height: 13px;
        text-align: center;
        transform: rotate(0deg);
    }
    .expandown1 img {
        width: 13px;
        height: 13px;
        text-align: center;
        transform: rotate(180deg);
    }
    .f2 .ant-drawer-content-wrapper {
        height: 400px !important;
    }
    .newcountry .react-tel-input .country-list {
        left: 10px;
    }
}
@media screen and (min-width: 320px) and (max-width: 600px) {
    .mainCard {
        display: block !important;
    }
    .marketingSignHome .mainCard .card:last-child {
        margin: 60px auto 0;
    }
    .sign-in-card {
        width: 100%;
        padding: 40px 30px;
    }
    .logo-text {
        font-size: 28px;
        margin-bottom: 30px;
    }
    .sign-in {
        font-size: 20px;
    }
    /* Merchant Profile Page By sahaj */
    .save button {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
    }
    .save {
        display: unset;
    }
    .save p {
        text-align: center;
        width: 100%;
        display: block; 
    }
    .profile .profileimg {
        width: 125px;
        height: 125px;
    }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
    header .inpay a h4 {
        font-size: 19px;
    }
    header .signbtn .sigin,
    header .signbtn .sigup {
        font-size: 12px;
        margin: 0 0 0 10px;
        padding: 5px 10px;
    }

} 
